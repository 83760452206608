import { useEffect, useRef } from "react";

import { useAppSelector } from "../../../redux/hooks";
import { MessageItem } from "../components/message-item";
import { Conversation } from "../entities/conversation.entity";
import { findFriend } from "../../../utils/find-friend";

interface ChatPageProps {
  messageInput: string;
  setMessageInput: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: (e: React.FormEvent<HTMLFormElement>) => void;
  selectedConversation?: Conversation | null;
  emitUserTypingMessage: (id?: string) => void;
  emitUserStopTypingMessage: (id?: string) => void;
  socketStatus: string;
}

let myText = "";
let isEmittedStop = false;

export function ChatSection({
  messageInput,
  setMessageInput,
  sendMessage,
  selectedConversation,
  emitUserTypingMessage,
  emitUserStopTypingMessage,
  socketStatus,
}: ChatPageProps) {
  const { selected, fetchMessageProgress } = useAppSelector(
    (state) => state.chatting
  );
  const { userId } = useAppSelector((state) => state.app);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selected?.messages?.length]);

  useEffect(() => {
    if (!selectedConversation?.id) {
      return;
    }
    const timer = setInterval(() => {
      if (myText) {
        console.log("Typing...");
        emitUserTypingMessage(selectedConversation?.id);
        myText = "";
        isEmittedStop = false;
      } else {
        if (!isEmittedStop) {
          console.log("Stop typing...");
          emitUserStopTypingMessage(selectedConversation?.id);
          myText = "";
          isEmittedStop = true;
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
      myText = "";
    };
  }, [selectedConversation?.id]);

  const friend = findFriend(selectedConversation?.users || []);
  const unreadUserExceptMe = (selectedConversation?.unreadUsers || []).filter(
    (item) => item !== userId
  );
  return (
    <div className="col-span-9 border-box flex flex-col overflow-y-scroll box-border chat-scrollbar-hide">
      <div className="chat-message-container overflow-y-scroll px-4 py-4 box-border h-full chat-scrollbar-hide">
        {fetchMessageProgress.status === "loading" && (
          <div className="text-center text-gray-400 mt-2">Loading...</div>
        )}
        {selected?.messages.map((message, index) => {
          return (
            <MessageItem
              key={`${new Date().getTime()}-${Math.random()}-${message.id}`}
              message={message}
              myId={userId}
              friend={friend}
              isRead={unreadUserExceptMe.length <= 0}
              isLastMessage={index === selected.messages.length - 1}
            />
          );
        })}
        <div ref={messagesEndRef} />
      </div>
      <form
        onSubmit={sendMessage}
        className="chat-input-container h-[68px] border-t border-solid border-gray-200 box-border"
      >
        <input
          type="text"
          className="chat-input h-full w-full p-2 text-base"
          placeholder="Type a message..."
          value={messageInput}
          onChange={(e) => {
            setMessageInput(e.target.value);
            myText = e.target.value;
          }}
          disabled={
            !selected ||
            fetchMessageProgress.status === "loading" ||
            socketStatus !== "ready"
          }
        />
      </form>
    </div>
  );
}
