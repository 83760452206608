import { useAppSelector } from "../../../redux/hooks";

export function useTotalUnreadConversation() {
  const {
    value: { conversations },
  } = useAppSelector((state) => state.conversations);
  const { userId } = useAppSelector((state) => state.app);

  const unreadMessages = conversations.map((conversation) => {
    return conversation.unreadUsers.filter((item) => item === userId).length;
  });

  return unreadMessages.reduce((acc, cur) => acc + cur, 0);
}
