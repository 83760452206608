import DoneIcon from "@mui/icons-material/Done";
import { RolePermission } from "../../permissions/entities/permission.entity";

export interface PermissionTableProps {
  permissions: RolePermission[];
  handleSelect?: (permission: RolePermission) => void;
}

export function PermissionTable({
  permissions,
  handleSelect,
}: PermissionTableProps) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full divide-y divide-gray-200 overflow-hidden">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Code
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {permissions.map((permission) => {
            let trClass =
              "select-none cursor-pointer bg-gray-50 hover:bg-gray-100";
            if (permission.isSelected) {
              trClass = trClass.concat(
                " bg-green-700 hover:bg-green-600 text-white"
              );
            }

            return (
              <tr
                key={permission.id}
                className={trClass}
                onClick={() => handleSelect && handleSelect(permission)}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  {permission.code}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {permission.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {permission.isAdded ? (
                    <DoneIcon
                      className={
                        permission.isSelected ? "text-white" : "text-green-500"
                      }
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
