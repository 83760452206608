import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app.reducer";
import introReducer from "./reducers/admin/about-me.reducer";
import logsReducer from "./reducers/admin/logs.reducer";
import educationsReducer from "./reducers/admin/education.reducer";
import experiencesReducer from "./reducers/admin/experience.reducer";
import skillsReducer from "./reducers/admin/skill.reducer";
import rolesReducer from "./reducers/admin/role.reducer";
import permissionReducer from "./reducers/admin/permission.reducer";
import usersReducer from "./reducers/chats/user.reducer";
import conversationsReducer from "./reducers/chats/conversation.reducer";
import chattingConversation from "./reducers/chats/chatting-reducer";
import expenseReducer from "./reducers/admin/expense.reducer";

export const store = configureStore({
  reducer: {
    app: appReducer,
    //admin pages start from here.
    intro: introReducer,
    logs: logsReducer,
    educations: educationsReducer,
    experiences: experiencesReducer,
    skills: skillsReducer,
    roles: rolesReducer,
    permissions: permissionReducer,
    // chat app start from here.
    users: usersReducer,
    conversations: conversationsReducer,
    chatting: chattingConversation,
    expense: expenseReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
