import "./css/experience.css";
import { useEffect } from "react";
import { axios } from "../../../configs/axios.config";
import { DashboardLayout } from "../layout/dashboard-layout";
import { Experience } from "./entities/experience.entity";
import { ExperienceSection } from "./sections/experience-section";
import { useForm } from "react-hook-form";
import { ExperienceList } from "./sections/experience-list";
import {
  useAppDispatch,
  useAppLoading,
  useAppSelector,
  useSuccessMessage,
} from "../../../redux/hooks";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RootState } from "../../../redux/store";
import {
  addExperience,
  adminExperienceActions,
  deleteExperienceAction,
  fetchExperience,
  updateExperiences,
  uploadImageAction,
} from "../../../redux/reducers/admin/experience.reducer";

export function ExperiencePage() {
  const setSuccessMessage = useSuccessMessage();
  const { setAppLoading, appLoading } = useAppLoading();
  const dispatch = useAppDispatch();

  const {
    value: experiences,
    selected,
    uploadImageStatus,
    uploadImageKey,
    uploadedSignedUrl,
    fetchingStatus,
    addStatus,
    updateStatus,
  } = useAppSelector((state: RootState) => state.experiences);

  const { register, handleSubmit, setValue, watch } = useForm();
  const getExperiences = async () => {
    dispatch(fetchExperience());
  };

  useEffect(() => {
    getExperiences();
  }, []);

  useEffect(() => {
    if (
      [fetchingStatus, addStatus, updateStatus, uploadImageStatus].some(
        (item) => item === "loading"
      )
    ) {
      setAppLoading(true);
    } else {
      setAppLoading(false);
    }
  }, [fetchingStatus, addStatus, updateStatus, uploadImageStatus]);

  useEffect(() => {
    const files = watch("image");
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        uploadImage(file);
      }
    }
  }, [watch("image")]);

  useEffect(() => {
    if (uploadImageStatus === "succeeded") {
      setValue("companyLogoUrl", uploadImageKey);
    }
  }, [uploadImageKey]);

  const uploadImage = async (file: File) => {
    await dispatch(uploadImageAction(file));
    setSuccessMessage("Uploaded successfully!");
  };

  const onSubmitExperience = async (data: any) => {
    await dispatch(updateExperiences(data));
    await getExperiences();
    setSuccessMessage("Updated successfully!");
  };

  const setSelected = (experience: Experience | null) => {
    dispatch(adminExperienceActions.setSelected(experience));
  };

  const onSelectExperience = (experience: Experience) => {
    setSelected(experience);
    setValue("periodCurrent", experience.periodCurrent);
    setValue("periodTo", experience.periodTo);
    setValue("jobTitle", experience.jobTitle);
    setValue("companyName", experience.companyName);
    setValue("periodStart", experience.periodStart);
    setValue("companyLocation", experience.companyLocation);
    setValue("companyLogoUrl", experience.companyLogoUrl);
    setValue("id", experience.id);
    for (let i = 1; i <= 5; i++) {
      setValue(`detail${i}`, experience.details[i - 1] || "");
    }
  };

  const onClearInput = () => {
    setSelected(null);
    setValue("periodCurrent", false);
    setValue("periodTo", "");
    setValue("jobTitle", "");
    setValue("companyName", "");
    setValue("periodStart", new Date().toISOString());
    setValue("periodTo", new Date().toISOString());
    setValue("companyLocation", "");
    setValue("companyLogoUrl", "default");
    setValue("id", "");
    setValue("detail1", "");
    setValue("detail2", "");
    setValue("detail3", "");
    setValue("detail4", "");
    setValue("detail5", "");
  };

  const onAddNewExperience = async () => {
    const details: string[] = [];
    const payload = JSON.parse(JSON.stringify(watch()));
    delete payload.id;
    delete payload.details;
    setAppLoading(true);

    if (payload.sortIndex) {
      payload.sortIndex = parseInt(payload.sortIndex);
    }

    for (let index = 1; index <= 5; index++) {
      if (watch(`detail${index}`)) {
        details.push(watch(`detail${index}`));
      }
    }

    const payloadAdd = {
      ...payload,
      periodTo: payload.periodTo ? payload.periodTo : null,
      periodStart: payload.periodStart ? payload.periodStart : null,
      details,
    };

    await dispatch(addExperience(payloadAdd));
    await getExperiences();
    setSuccessMessage("Added successfully!");
  };

  const onDeleteExperience = async (experience?: Experience | null) => {
    confirmAlert({
      title: "Confirmation",
      message: `Are you sure you want to delete ${experience?.companyName}`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            deleteExperience(experience as Experience);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteExperience = async (experience?: Experience | null) => {
    if (experience) {
      await dispatch(deleteExperienceAction(experience));
      await getExperiences();
      setSuccessMessage("Deleted successfully!");
    }
  };

  const sort = async (
    experience: Experience | null | undefined,
    type: "decreaseIndex" | "increaseIndex"
  ) => {
    if (experience) {
      setAppLoading(true);
      await axios.put(`/admin/resume/experience/${experience.id}/sort`, {
        type,
      });
      getExperiences();
    }
  };

  return (
    <DashboardLayout>
      <div className="grid grid-cols-12 gap-4 p-4">
        <div className="col-span-5">
          <form onSubmit={handleSubmit(onSubmitExperience)}>
            <ExperienceSection
              register={register}
              isLoading={appLoading}
              onAddNewExperience={onAddNewExperience}
              onDeleteExperience={onDeleteExperience}
              onClearInput={onClearInput}
              selected={selected}
              sort={sort}
              uploadedUrl={uploadedSignedUrl}
            />
          </form>
        </div>
        <div className="col-span-7">
          <ExperienceList
            experiences={experiences}
            onSelectExperience={onSelectExperience}
            selected={selected}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
