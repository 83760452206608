import { getImage } from "../../../../utils/get-image";
import {
  calculateDuration,
  formatDateString,
} from "../../../../utils/format-date";
import { Experience } from "../entities/experience.entity";

export interface ExperienceSectionProps {
  experiences: Experience[];
  selected?: Experience | null;
  onSelectExperience?: (experiences: Experience) => void;
}

export function ExperienceList({
  experiences,
  selected,
  onSelectExperience,
}: ExperienceSectionProps) {
  return (
    <div className="p-3" style={{ maxHeight: "100%" }}>
      {experiences.map((experience, index) => {
        const isSelected = selected && selected.id === experience.id;
        return (
          <div
            key={`experience-${index}`}
            className="flex justify-start mt-10 experience-item p-3"
            style={{
              ...(isSelected
                ? { background: "#005313", color: "#ffffff" }
                : {}),
            }}
            onClick={() => onSelectExperience && onSelectExperience(experience)}
          >
            <div className="experience-item p-3" style={{ width: "120px" }}>
              <img
                src={getImage(experience.companyLogoPublicUrl)}
                alt="company-logo"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div className="pl-5" style={{ width: "100%" }}>
              <p className="font-sans text-justify font-bold text-xl">
                {experience.jobTitle}
              </p>
              <p className="font-sans text-justify text-xl">
                {experience.companyName}
              </p>
              <p className="font-sans text-justify text-base font-extralight">
                {formatDateString(
                  experience.periodStart,
                  experience.periodCurrent
                )}{" "}
                -{" "}
                {formatDateString(
                  experience.periodTo,
                  experience.periodCurrent
                )}{" "}
                ·{" "}
                {calculateDuration(
                  experience.periodStart,
                  experience.periodTo,
                  "months",
                  experience.periodCurrent
                )}{" "}
                months
                {/* {experience.duration} */}
              </p>
              <p className="font-sans text-justify text-base font-extralight">
                {experience.companyLocation}
              </p>
              <p className="font-sans text-justify text-base font-extralight">
                Sort Index {experience.sortIndex}
              </p>
              <ul className="font-sans text-justify text-base list-disc pl-5">
                {experience.details.map((detail, index) => (
                  <li key={`detail-${index}`}>{detail}</li>
                ))}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
}
