type MenuItemProps = {
    navigate: any, path: string, name: string, currentPath: string
}

export function MenuItem({ navigate, path, name, currentPath }: MenuItemProps) {
    return (
        <div
            className='site-menu-item'
            onClick={() => navigate(path)}
            style={{ ...(currentPath === path ? { fontWeight: "bold" } : {}) }}
        >
            {name}
        </div>
    )
}