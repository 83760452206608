import { getImage } from "../../../../utils/get-image";
import {
  calculateDuration,
  formatDateString,
} from "../../../../utils/format-date";
export interface Skill {
  skillLogoUrl: any;
  skillLogoPublicUrl: any;
  skillUrl: string;
  skillName: string;
  skillUsedAt: string;
  periodStart: Date;
  periodTo: Date | null;
  unit: string;
  isMainSkill: boolean;
  periodCurrent: boolean;
}

export interface SkillSectionProps {
  skills: Skill[];
}

export interface SkillItemProps {
  skill: Skill;
  index: number;
}

export function SkillItem({ skill, index }: SkillItemProps) {
  return (
    <div
      key={`skill-${index}`}
      className="flex items-center justify-start mt-10"
    >
      <div className="" style={{ width: "120px" }}>
        <img
          src={getImage(skill.skillLogoPublicUrl)}
          alt="company-logo"
          style={{ width: "100%", height: "auto" }}
        />
      </div>
      <div className="p-3" style={{ width: "100%" }}>
        <p className="font-sans text-justify font-bold text-xl">
          <a href={skill.skillUrl}>{skill.skillName}</a>
        </p>
        <p className="font-sans text-justify text-lg">{skill.skillUsedAt}</p>
        <p className="font-sans text-justify text-base font-extralight">
          {formatDateString(skill.periodStart, skill.periodCurrent)} -{" "}
          {formatDateString(skill.periodTo, skill.periodCurrent)} ·{" "}
          {calculateDuration(
            skill.periodStart,
            skill.periodTo,
            skill.unit as "months" | "years",
            skill.periodCurrent
          )}{" "}
          {skill.unit}
        </p>
      </div>
    </div>
  );
}

export function SkillSection({ skills }: SkillSectionProps) {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 md:col-span-6">
        <p className="text-xl md:text-2xl font-bold font-mono mt-10">
          Main Skills
        </p>
        <hr />
        {skills
          .filter((skill) => skill.isMainSkill)
          .map((skill, index) => {
            return (
              <SkillItem
                skill={skill}
                index={index}
                key={`${Math.random()}-${skill.skillLogoUrl}`}
              />
            );
          })}
      </div>
      <div className="col-span-12 md:col-span-6">
        <p className="text-xl md:text-2xl font-bold font-mono mt-10">
          Soft Skills
        </p>
        <hr />
        {skills
          .filter((skill) => !skill.isMainSkill)
          .map((skill, index) => {
            return (
              <SkillItem
                skill={skill}
                index={index}
                key={`${Math.random()}-${skill.skillLogoUrl}`}
              />
            );
          })}
      </div>
    </div>
  );
}
