import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../configs/axios.config";
import { Role } from "../../../pages/admin/roles/entities/role.entity";
import { genActionType } from "../../../utils";
import {
  Permission,
  RolePermission,
} from "../../../pages/admin/permissions/entities/permission.entity";
import { User } from "../../../types/user";

enum FetchStatus {
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
  NOT_INIT = "not_init",
}

interface AdminRoleState {
  value: Role[];
  fetchRoleStatus: {
    status: FetchStatus;
    error?: any;
  };
  permissions: Permission[];
  users: User[]; // users in roles (for example: all users in admin role)
  searchUsers: User[]; // list of users that can be added to a role
  fetchUserStatus: {
    status: FetchStatus;
    error?: any;
  };
  addUserToRoleStatus: {
    status: FetchStatus;
    error?: any;
  };
  fetchSearchUserStatus: {
    status: FetchStatus;
    error?: any;
  };
  fetchPermissionStatus: {
    status: FetchStatus;
    error?: any;
  };
  deleteUserFromRoleStatus: {
    status: FetchStatus;
    error?: any;
  };
  rolePermissions: RolePermission[];
  fetchRolePermissionStatus: {
    status: FetchStatus;
    error?: any;
  };
  selected: Role | null;
}

const initialState: AdminRoleState = {
  value: [],
  users: [],
  searchUsers: [],
  fetchRoleStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  addUserToRoleStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  fetchSearchUserStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  deleteUserFromRoleStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  fetchUserStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  permissions: [],
  fetchPermissionStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  rolePermissions: [],
  fetchRolePermissionStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  selected: null,
};

const fetchRoleActions = createAsyncThunk(
  genActionType("admin/role", "fetch-role"),
  async () => {
    try {
      const res = await axios.get("/admin/role");
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

const fetchPermissionAction = createAsyncThunk(
  genActionType("admin/role", "fetch-permission"),
  async () => {
    const res = await axios.get("/admin/permission");
    return res.data;
  }
);

const fetchSearchUsersActions = createAsyncThunk(
  genActionType("admin/role", "fetch-search-users"),
  async (data: any) => {
    const res = await axios.get(`/admin/user?search=${data.search}`);
    return res.data;
  }
);

const addRoleAction = createAsyncThunk(
  genActionType("admin/role", "add-roles"),
  async (data: any) => {
    const res = await axios.post("/admin/role", data);
    return res.data;
  }
);

const fetchRolePermissionAction = createAsyncThunk(
  genActionType("admin/role", "fetch-role-permission"),
  async (roleCode: string) => {
    const res = await axios.get(`/admin/role/${roleCode}/permission`);
    return res.data;
  }
);

const fetchUsersByRoleCode = createAsyncThunk(
  genActionType("admin/role", "fetch-role-users"),
  async (roleCode: string) => {
    try {
      const res = await axios.get(`/admin/role/${roleCode}/user`);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

const deleteUserFromRole = createAsyncThunk(
  genActionType("admin/role", "delete-role-users"),
  async (data: any) => {
    const res = await axios.delete(`/admin/role/${data.roleCode}/user`, {
      data: {
        userId: data.userId,
      },
    });
    return res.data;
  }
);

const addUserToRoleActions = createAsyncThunk(
  genActionType("admin/role", "add-role-users"),
  async (data: any) => {
    const res = await axios.post(`/admin/role/${data.roleCode}/user`, {
      userId: data.userId,
    });
    return res.data;
  }
);

const addRolePermissionAction = createAsyncThunk(
  genActionType("admin/role", "add-role-permission"),
  async (data: any) => {
    const { roleCode, codes } = data;
    const res = await axios.post(`/admin/role/${roleCode}/permission`, {
      codes,
    });
    return res.data;
  }
);

const removeRolePermissionAction = createAsyncThunk(
  genActionType("admin/role", "remove-role-permission"),
  async (data: any) => {
    const { roleCode, codes } = data;
    const res = await axios.delete(`/admin/role/${roleCode}/permission`, {
      data: { codes },
    });
    return res.data;
  }
);

const removeRole = createAsyncThunk(
  genActionType("admin/role", "remove-role"),
  async (roleCode: any) => {
    try {
      const res = await axios.delete(`/admin/role/${roleCode}`);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const adminRoleSlice = createSlice({
  name: "admin-role-page",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    selectRolePermission: (state, action) => {
      const currentRolePermission = JSON.parse(
        JSON.stringify(state.rolePermissions)
      );
      const indexFind = (currentRolePermission as RolePermission[]).findIndex(
        (item) => item.code === action.payload.code
      );

      if (indexFind !== -1) {
        currentRolePermission[indexFind].isSelected =
          !!!currentRolePermission[indexFind].isSelected;
        state.rolePermissions = currentRolePermission;
      }
    },
    mapRolePermission: (state, action) => {
      const { permissions, rolePermissions } = JSON.parse(
        JSON.stringify(action.payload)
      );
      const formatPayload: RolePermission[] = (permissions as Permission[]).map(
        (permission) => {
          return {
            ...permission,
            isAdded: rolePermissions.find(
              (rolePer: RolePermission) => rolePer.code === permission.code
            )
              ? true
              : false,
            isSelected: false,
          };
        }
      );
      state.rolePermissions = formatPayload;
    },
    emptyRolePermissions: (state) => {
      state.rolePermissions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleActions.pending, (state) => {
        state.fetchRoleStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(fetchRoleActions.fulfilled, (state, action) => {
        state.value = action.payload;
        state.fetchRoleStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(fetchRoleActions.rejected, (state, action) => {
        state.value = [];
        state.fetchRoleStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(fetchPermissionAction.pending, (state) => {
        state.fetchPermissionStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(fetchPermissionAction.fulfilled, (state, action) => {
        state.permissions = action.payload;
        state.fetchPermissionStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(fetchPermissionAction.rejected, (state, action) => {
        state.permissions = [];
        state.fetchPermissionStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(fetchUsersByRoleCode.pending, (state) => {
        state.fetchUserStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(fetchUsersByRoleCode.fulfilled, (state, action) => {
        state.users = action.payload;
        state.fetchUserStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(fetchUsersByRoleCode.rejected, (state, action) => {
        state.fetchUserStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(deleteUserFromRole.pending, (state) => {
        state.deleteUserFromRoleStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(deleteUserFromRole.fulfilled, (state, action) => {
        state.deleteUserFromRoleStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(deleteUserFromRole.rejected, (state, action) => {
        state.deleteUserFromRoleStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(fetchSearchUsersActions.pending, (state) => {
        state.fetchSearchUserStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(fetchSearchUsersActions.fulfilled, (state, action) => {
        state.fetchSearchUserStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
        state.searchUsers = action.payload;
      })
      .addCase(fetchSearchUsersActions.rejected, (state, action) => {
        state.fetchSearchUserStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(addUserToRoleActions.pending, (state) => {
        state.addUserToRoleStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(addUserToRoleActions.fulfilled, (state, action) => {
        state.addUserToRoleStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(addUserToRoleActions.rejected, (state, action) => {
        state.addUserToRoleStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      });
  },
});

export const adminRoleActions = adminRoleSlice.actions;
export default adminRoleSlice.reducer;
export {
  fetchRoleActions,
  fetchPermissionAction,
  addRoleAction,
  fetchRolePermissionAction,
  addRolePermissionAction,
  removeRolePermissionAction,
  removeRole,
  fetchUsersByRoleCode,
  deleteUserFromRole,
  fetchSearchUsersActions,
  addUserToRoleActions,
};
