import { getImage } from "../../../../utils/get-image";

export interface IntroProps {
  register?: any;
  isLoading?: boolean;
  uploadedUrl?: string;
  uploadingImage?: boolean;
}

export function IntroSection({
  register,
  isLoading,
  uploadedUrl,
  uploadingImage,
}: IntroProps) {
  return (
    <>
      <p className="text-2xl font-bold text-slate-800">Introduce</p>
      <div>
        <div className="mt-3 text-left">
          <p className="italic">Choose a image</p>
          <div className="flex justify-between items-center">
            <div>
              <input type="file" {...register("image")} />
            </div>
            {uploadingImage && <p>Uploading...</p>}
            <div>
              <img
                src={getImage(uploadedUrl)}
                alt="something-special"
                className="h-16 w-16"
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p className="italic">Name</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your name"
            {...register("name")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Job Title</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your name"
            {...register("jobTitle")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Email</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your email"
            {...register("email")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Phone</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your phone number"
            {...register("phone")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Objective</p>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Type your objective"
            rows={10}
            {...register("objective")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Facebook</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your facebook link"
            {...register("facebookUrl")}
          />
        </div>
        <div className="hidden">
          <input {...register("avatarUrl")} />
        </div>
        <div className="mt-3 text-right">
          <button
            disabled={isLoading}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded active:translate-y-1"
          >
            {!isLoading ? "Update" : "Updating..."}
          </button>
        </div>
      </div>
    </>
  );
}
