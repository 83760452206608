import "./css/chat-css.css";
import { useEffect, useState } from "react";
import { IconButton, LinearProgress } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLogout } from "../../hooks/use-logout";
import { UserSection } from "./sections/user-section";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ChatSection } from "./sections/chat-section";
import { getImage } from "../../utils/get-image";
import { findFriend, findMe } from "../../utils/find-friend";
import {
  chatConversationActions,
  fetchConversationAction,
} from "../../redux/reducers/chats/conversation.reducer";
import {
  EVENT_CLIENT_MESSAGE_STOP_TYPING,
  EVENT_CLIENT_MESSAGE_TYPING,
  EVENT_CONVERSATION_CLIENT_READ,
  EVENT_CONVERSATION_CLIENT_SEND_MESSAGE,
  EVENT_CONVERSATION_JOIN,
  EVENT_CONVERSATION_SERVER_READ,
  EVENT_CONVERSATION_SERVER_SEND_MESSAGE,
  EVENT_SERVER_MESSAGE_STOP_TYPING,
  EVENT_SERVER_MESSAGE_TYPING,
  EVENT_SERVER_SEND_CONVERSATION_CHANGE,
} from "../../common/events/event";
import { SendMessageDto } from "./dto/send-message.dto";
import {
  chatPageChatConversationActions,
  fetchMessageAction,
} from "../../redux/reducers/chats/chatting-reducer";
import { Conversation } from "./entities/conversation.entity";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { ReadMessageDto } from "./dto/read-message.dto";
import { useSocket } from "./hooks/use-socket";
import { useTotalUnreadConversation } from "./hooks/use-total-unread-conversation";
import { SocketStatus } from "./enum/socket-status.enum";

const defaultDocumentTitle = document.title;

export function ChatPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const totalUnreadConversation = useTotalUnreadConversation();
  const { chatConversations } = useAppSelector((state) => state.chatting);
  const { appLoading, userId } = useAppSelector((state) => state.app);
  const { selected: selectedConversation } = useAppSelector(
    (state) => state.conversations
  );
  const { socket, socketStatus } = useSocket(selectedConversation);
  const [messageInput, setMessageInput] = useState("");

  const { handleSignout } = useLogout();
  const fetchConversation = (search = "") => {
    dispatch(fetchConversationAction({ search }));
  };

  const sendMessage = async (e: any) => {
    e.preventDefault();
    const myId = findMe(selectedConversation?.users || [])?.id;
    if (socket && myId) {
      socket.emit(EVENT_CONVERSATION_CLIENT_SEND_MESSAGE, {
        conversationId: selectedConversation?.id,
        body: messageInput,
        userId: myId,
        replyToMessageId: null,
        participants: (selectedConversation?.users || []).map(
          (userItem) => userItem.id
        ),
      } as SendMessageDto);
    }
    setMessageInput("");
  };

  const onServerSendMessage = (data: SendMessageDto) => {
    dispatch(
      chatPageChatConversationActions.addMessages({
        conversationId: data.conversationId,
        messages: [data],
      })
    );
  };

  const onConversationUpdate = (data: SendMessageDto) => {
    dispatch(chatPageChatConversationActions.updateLastMessage(data));
    dispatch(chatConversationActions.updateLastMessageAndUnread(data));
  };

  useEffect(() => {
    if (totalUnreadConversation > 0) {
      document.title = `(${totalUnreadConversation}) ${defaultDocumentTitle}`;
    } else {
      document.title = defaultDocumentTitle;
    }
  }, [totalUnreadConversation]);

  const joinConversation = (conversationId: string) => {
    socket.emit(EVENT_CONVERSATION_JOIN, {
      conversationId,
    });
  };

  const onServerRead = (data: ReadMessageDto) => {
    dispatch(chatPageChatConversationActions.readMessage(data));
    dispatch(chatConversationActions.readMessage(data));
  };

  const clearMountedConversation = () => {
    dispatch(chatConversationActions.setToDefault());
    dispatch(chatPageChatConversationActions.setToDefault());
  };

  const onMessageTyping = (data: {
    conversationId: string;
    userId: string;
  }) => {
    dispatch(chatPageChatConversationActions.typingMessage(data));
    dispatch(chatConversationActions.typingMessage(data));
  };

  const onMessageStopTyping = (data: {
    conversationId: string;
    userId: string;
  }) => {
    dispatch(chatPageChatConversationActions.unTypingMessage(data));
    dispatch(chatConversationActions.unTypingMessage(data));
  };

  useEffect(() => {
    fetchConversation();
    return () => {
      clearMountedConversation();
    };
  }, []);

  useEffect(() => {
    socket.on(EVENT_CONVERSATION_SERVER_SEND_MESSAGE, onServerSendMessage);
    socket.on(EVENT_CONVERSATION_SERVER_READ, onServerRead);
    socket.on(EVENT_SERVER_MESSAGE_TYPING, onMessageTyping);
    socket.on(EVENT_SERVER_MESSAGE_STOP_TYPING, onMessageStopTyping);
    socket.on(EVENT_SERVER_SEND_CONVERSATION_CHANGE, onConversationUpdate);
    return () => {
      socket.off(EVENT_CONVERSATION_SERVER_SEND_MESSAGE, onServerSendMessage);
      socket.off(EVENT_SERVER_MESSAGE_TYPING, onMessageTyping);
      socket.off(EVENT_CONVERSATION_SERVER_READ, onServerRead);
      socket.off(EVENT_SERVER_MESSAGE_STOP_TYPING, onMessageStopTyping);
      socket.off(EVENT_SERVER_SEND_CONVERSATION_CHANGE, onConversationUpdate);
    };
  }, [selectedConversation?.id]);

  const selectConversation = (conversation: Conversation) => {
    dispatch(chatConversationActions.setSelected(conversation));
    dispatch(chatPageChatConversationActions.setSelected(conversation));
    const chatConversation = chatConversations.find(
      (c) => c.id === conversation.id
    );
    if (!chatConversation || chatConversation?.messages?.length <= 0) {
      dispatch(fetchMessageAction({ conversationId: conversation.id }));
    }
    joinConversation(conversation.id);
    socket.emit(EVENT_CONVERSATION_CLIENT_READ, {
      conversationId: conversation.id,
      userId: findMe(conversation.users)?.id,
    });
  };

  const emitUserTypingMessage = (conversationId?: string) => {
    socket.emit(EVENT_CLIENT_MESSAGE_TYPING, {
      conversationId,
    });
  };

  const emitUserStopTypingMessage = (conversationId?: string) => {
    socket.emit(EVENT_CLIENT_MESSAGE_STOP_TYPING, {
      conversationId,
    });
  };

  const friend = findFriend(selectedConversation?.users || []);
  return (
    <div className="chat-app-container flex flex-col overflow-hidden">
      {appLoading && (
        <div className="fixed top-0 z-20 w-full">
          <LinearProgress />
        </div>
      )}
      {![SocketStatus.INITIALIZING, SocketStatus.READY].includes(
        socketStatus
      ) && (
        <div className="fixed top-3 z-30 w-full text-center">
          Lost connection, retrying to connect...
        </div>
      )}

      <div className="fixed w-full top-0 left-0 border-box">
        <div className="h-[48px] chat-header border-box">
          <div className="grid grid-cols-12 h-full border-box">
            <div className="col-span-3 flex items-center">
              <h6 className="text-2xl logo border-box font-bold font-sans flex items-center ml-3">
                ThanhPhanV
              </h6>
            </div>
            <div className="col-span-9">
              <div className="flex items-center justify-between h-full mr-3">
                <div>
                  {selectedConversation && (
                    <div className="flex items-center">
                      <div
                        className={
                          "chat-user-item-header rounded-3xl flex items-center"
                        }
                      >
                        <div className="h-[24px] w-[24px] rounded-full overflow-hidden">
                          <img src={getImage(friend?.imageUrl || "default")} />
                        </div>
                        <div className="px-4">
                          <div className="font-bold font-sans text-base">
                            {friend?.firstName} {friend?.lastName}
                          </div>
                        </div>
                      </div>
                      <div>
                        {(selectedConversation.typingUsers || []).filter(
                          (userItemId) => userId && userItemId !== userId
                        ).length
                          ? "Typing..."
                          : ""}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <IconButton onClick={() => navigate("/dashboard/profile")}>
                    <AccountCircleIcon />
                  </IconButton>
                  <IconButton onClick={handleSignout}>
                    <LogoutIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 border-box flex-grow mt-[48px] overflow-y-scroll chat-scrollbar-hide">
        <UserSection
          joinConversation={joinConversation}
          selectConversation={selectConversation}
        />
        <ChatSection
          messageInput={messageInput}
          setMessageInput={setMessageInput}
          sendMessage={sendMessage}
          selectedConversation={selectedConversation}
          emitUserTypingMessage={emitUserTypingMessage}
          emitUserStopTypingMessage={emitUserStopTypingMessage}
          socketStatus={socketStatus}
        />
      </div>
    </div>
  );
}
