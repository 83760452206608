import { useEffect, useState } from "react";
import { DashboardLayout } from "../layout/dashboard-layout";
import LogTable from "./log-table";
import {
  useAppDispatch,
  useAppLoading,
  useAppSelector,
} from "../../../redux/hooks";
import { fetchLogs } from "../../../redux/reducers/admin/logs.reducer";

export function LogPage() {
  const { setAppLoading } = useAppLoading();
  const { value: logs, fetchingStatus } = useAppSelector((state) => state.logs);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(fetchLogs(""));
  }, [dispatch]);

  useEffect(() => {
    if (fetchingStatus === "loading") {
      setAppLoading(true);
    } else {
      setAppLoading(false);
    }
  }, [fetchingStatus]);

  useEffect(() => {
    const params = `?search=${search}`;

    const timeout = setTimeout(() => {
      dispatch(fetchLogs(params));
      clearTimeout(timeout);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  return (
    <DashboardLayout>
      <div className="">
        <div
          className="mb-4"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <input
            placeholder="Search"
            style={{ borderRadius: "24px", padding: "14px", width: "300px" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <LogTable rows={logs} />
      </div>
    </DashboardLayout>
  );
}
