import { PrivateRoute } from "./common/components/private-route";
import { SignInPage } from "./pages/auth/sign-in.page";
import { HomeDashboardPage } from "./pages/admin/home";
import { LogPage } from "./pages/admin/log/log-page";
import { IntroPage } from "./pages/admin/intro";
import { LandingPage } from "./pages/landing/landing.page";
import { UserPage } from "./pages/user/user.page";
import { EducationPage } from "./pages/admin/education";
import { SkillPage } from "./pages/admin/skill";
import { ExperiencePage } from "./pages/admin/experience";
import { RolePage } from "./pages/admin/roles";
import { PermissionPage } from "./pages/admin/permissions/permission-page";
import { ChatPage } from "./pages/chat/chat-page";
import { ProfilePage } from "./pages/dashboard/profile/profile";
import { DashboardHomePage } from "./pages/dashboard/home/home";
import {
  ExpenseOverviewPages,
  ExpenseManagementPages,
} from "./pages/admin/expenses";

export const routes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/sign-in",
    element: <SignInPage />,
  },
  {
    path: "/admin/log",
    element: (
      <PrivateRoute>
        <LogPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin",
    element: (
      <PrivateRoute>
        <HomeDashboardPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/intro",
    element: (
      <PrivateRoute>
        <IntroPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/education",
    element: (
      <PrivateRoute>
        <EducationPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/skill",
    element: (
      <PrivateRoute>
        <SkillPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/experience",
    element: (
      <PrivateRoute>
        <ExperiencePage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/role",
    element: (
      <PrivateRoute>
        <RolePage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/permission",
    element: (
      <PrivateRoute>
        <PermissionPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/expense/overview",
    element: (
      <PrivateRoute>
        <ExpenseOverviewPages />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/expense/management",
    element: (
      <PrivateRoute>
        <ExpenseManagementPages />
      </PrivateRoute>
    ),
  },
  {
    path: "/user",
    element: (
      <PrivateRoute>
        <UserPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard/profile",
    element: (
      <PrivateRoute>
        <ProfilePage />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <DashboardHomePage />
      </PrivateRoute>
    ),
  },
  {
    path: "/chat",
    element: (
      <PrivateRoute>
        <ChatPage />
      </PrivateRoute>
    ),
  },
];
