import { Socket, io } from "socket.io-client";
enum SERVER_PATH {
  LOCAL = "http://localhost:3001",
  PRODUCTION = "https://thanhphanv.com",
}

export const socket = io(
  process.env.REACT_APP_NODE_ENV === "production"
    ? SERVER_PATH.PRODUCTION
    : SERVER_PATH.LOCAL
);
export function connectSocket(): Socket {
  return io(
    process.env.REACT_APP_NODE_ENV === "production"
      ? SERVER_PATH.PRODUCTION
      : SERVER_PATH.LOCAL
  );
}
