import NestJsLogo from "../assets/nestjs.jpg";
import avatarImage from "../assets/thanh-phan.png";
import CanThoUniversityLogo from "../assets/dai-hoc-can-tho.png";
import CuscLogo from "../assets/cusc.png";
import AmaLogo from "../assets/anh-ngu-ama.jpg";
import NashTechLogo from "../assets/nashTechLogo-red-.webp";
import FptLogo from "../assets/fpt-software.png";
import AppcoreLogo from "../assets/appcore.png";
import NodeJSLogo from "../assets/nodejs-1.png";
import ReactJSLogo from "../assets/reactjs.png";
import ExpressLogo from "../assets/expressjs.jpeg";
import KubernetesLogo from "../assets/kubernetes.png";
import DatabaseLogo from "../assets/database.png";
import ElasticSearchLogo from "../assets/elastic-search.png";
import DockerLogo from "../assets/docker.png";
import DefaultImage from "../assets/default-image.png";

export function getImage(url?: string) {
  switch (url) {
    case "nestjs":
      return NestJsLogo;

    case "nodejs":
      return NodeJSLogo;

    case "reactjs":
      return ReactJSLogo;

    case "expressjs":
      return ExpressLogo;

    case "kubernetes":
      return KubernetesLogo;

    case "database":
      return DatabaseLogo;

    case "elastic-search":
      return ElasticSearchLogo;

    case "docker":
      return DockerLogo;

    case "avatar":
      return avatarImage;

    case "cusc":
      return CuscLogo;

    case "ama":
      return AmaLogo;

    case "nashtech":
      return NashTechLogo;

    case "fptsoftware":
      return FptLogo;

    case "appcore":
      return AppcoreLogo;

    case "can-tho-university":
      return CanThoUniversityLogo;

    case "default":
      return DefaultImage;

    default:
      return url;
  }
}
