import "./css/intro.css";
import { useEffect, useState } from "react";
import { axios } from "../../../configs/axios.config";
import { DashboardLayout } from "../layout/dashboard-layout";
import { IntroSection } from "./sections/intro-section";
import { set, useForm } from "react-hook-form";
import {
  useAppDispatch,
  useAppLoading,
  useAppSelector,
  useSuccessMessage,
} from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
  fetchIntro,
  updateIntro,
  uploadImage as uploadImageAction,
} from "../../../redux/reducers/admin/about-me.reducer";
import { useFailedMessage } from "../../../redux/hooks/use-fail-message";

export function IntroPage() {
  const setSuccessMessage = useSuccessMessage();
  const setFailMessage = useFailedMessage();
  const dispatch = useAppDispatch();
  const { setAppLoading, appLoading } = useAppLoading();
  const {
    value: intro,
    fetchingStatus,
    fetchingError,
    updateStatus,
    uploadedSignedUrl,
    uploadImageStatus,
    uploadImageKey,
  } = useAppSelector((state: RootState) => state.intro);
  const { register, handleSubmit, setValue, watch } = useForm();
  const getIntro = async () => {
    dispatch(fetchIntro());
  };

  useEffect(() => {
    console.log("fetchingError", fetchingError);
    getIntro();
  }, []);

  useEffect(() => {
    if (fetchingStatus === "loading") {
      setAppLoading(true);
    }

    if (fetchingStatus === "failed") {
      setAppLoading(false);
      setFailMessage("Failed to fetch intro");
    }

    if (fetchingStatus === "succeeded") {
      setAppLoading(false);
    }
  }, [fetchingStatus]);

  useEffect(() => {
    if (updateStatus === "loading") {
      setAppLoading(true);
    }

    if (updateStatus === "failed") {
      setAppLoading(false);
      setFailMessage("Failed to fetch intro");
    }

    if (updateStatus === "succeeded") {
      setAppLoading(false);
      setSuccessMessage("Updated intro successfully");
    }
  }, [updateStatus]);

  useEffect(() => {
    if (intro) {
      setValue("name", intro.name);
      setValue("email", intro.email);
      setValue("phone", intro.phone);
      setValue("objective", intro.objective);
      setValue("facebookUrl", intro.facebookUrl);
      setValue("jobTitle", intro.jobTitle);
      setValue("avatarUrl", intro.avatarUrl);
    }
  }, [intro, setValue]);

  useEffect(() => {
    const files = watch("image");
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        uploadImage(file);
      }
    }
  }, [watch("image")]);

  useEffect(() => {
    if (uploadImageKey) {
      setValue("avatarUrl", uploadImageKey);
    }
  }, [uploadImageKey]);

  const uploadImage = async (file: File) => {
    dispatch(uploadImageAction(file));
  };

  const onSubmitIntro = async (data: any) => {
    dispatch(updateIntro(data));
  };

  return (
    <DashboardLayout>
      <div className="grid grid-cols-12 gap-4 p-4">
        <div className="col-span-6">
          <form onSubmit={handleSubmit(onSubmitIntro)}>
            <IntroSection
              register={register}
              isLoading={appLoading}
              uploadingImage={uploadImageStatus === "loading"}
              uploadedUrl={uploadedSignedUrl}
            />
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
}
