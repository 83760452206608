import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../configs/firebase.config";

export function useLogout() {
  const navigate = useNavigate();

  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("token");
        navigate("/sign-in");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return { handleSignout };
}
