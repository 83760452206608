import { IconButton } from "@mui/material";
import { formatDate } from "../../../../utils/date-util";
import { Role } from "../entities/role.entity";
import DeleteIcon from "@mui/icons-material/Delete";

export interface RoleTableProps {
  roles: Role[];
  removeRole: (role: Role) => void;
  setSelectedRole: (role: Role) => void;
  selectedRole: Role | null;
}

export function RoleTable({
  roles,
  removeRole,
  setSelectedRole,
  selectedRole,
}: RoleTableProps) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full divide-y divide-gray-200 overflow-hidden">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Code
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Created Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {roles.map((role) => {
            const isSelected = role.code === (selectedRole?.code || "");
            return (
              <tr
                key={role.id}
                className="select-none hover:bg-gray-50 cursor-pointer active:translate-y-1"
                onClick={() => setSelectedRole(role)}
                style={{
                  ...(isSelected ? { background: "#e7e7e7" } : {}),
                }}
              >
                <td className="px-6 py-4 whitespace-nowrap">{role.code}</td>
                <td className="px-6 py-4 whitespace-nowrap">{role.title}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {role.description}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {formatDate(role.createdAt || new Date())}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <IconButton onClick={() => removeRole(role)}>
                    <DeleteIcon className="text-red-600" />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
