import { getImage } from "../../../../utils/get-image";
import {
  calculateDuration,
  formatDateString,
} from "../../../../utils/format-date";
export interface Education {
  schoolLogoUrl: string;
  schoolLogoPublicUrl: string;
  schoolUrl: string;
  schoolName: string;
  studyTitle: string;
  periodStart: Date;
  periodTo: Date;
  periodCurrent: boolean;
  schoolLocation: string;
  unit: string;
}

export interface EducationSectionProps {
  educations: Education[];
}

export function EducationSection({ educations }: EducationSectionProps) {
  return (
    <>
      <p className="text-xl md:text-2xl font-bold font-mono mt-10">Education</p>
      <hr />
      <div>
        {educations.map((education, index) => {
          return (
            <div
              key={`education-${index}`}
              className="flex items-center justify-start mt-10"
            >
              <div className="education-item" style={{ width: "120px" }}>
                <img
                  src={getImage(education.schoolLogoPublicUrl)}
                  alt="company-logo"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className="p-3" style={{ width: "100%" }}>
                <p className="font-sans text-justify font-bold text-xl">
                  {education.studyTitle}
                </p>
                <p className="font-sans text-justify text-lg">
                  <a href={education.schoolUrl}>{education.schoolName}</a>
                </p>
                <p className="font-sans text-justify text-base font-extralight">
                  {formatDateString(
                    education.periodStart,
                    education.periodCurrent
                  )}{" "}
                  -{" "}
                  {formatDateString(
                    education.periodTo,
                    education.periodCurrent
                  )}{" "}
                  ·{" "}
                  {calculateDuration(
                    education.periodStart,
                    education.periodTo,
                    education.unit as "months" | "years",
                    education.periodCurrent
                  )}{" "}
                  {education.unit}
                </p>
                <p className="font-sans text-justify text-base font-extralight">
                  {education.schoolLocation}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
