import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../configs/axios.config";
import {
  ADD_SKILL,
  DELETE_SKILL,
  FETCH_SKILL,
  UPDATE_SKILL,
  UPDATE_LOADING_IMAGE,
} from "../../types";
import axiosDefault from "axios";
import { Skill } from "../../../pages/admin/skill/entities/skill.entity";

interface AdminSkillState {
  value: Skill[];
  selected: Skill | null;
  fetchingStatus: null | "loading" | "succeeded" | "failed";
  fetchingError?: any;
  updateStatus: null | "loading" | "succeeded" | "failed";
  updateError?: any;
  addStatus: null | "loading" | "succeeded" | "failed";
  addError?: any;
  sortStatus: null | "loading" | "succeeded" | "failed";
  sortError?: any;
  uploadedSignedUrl?: string;
  uploadImageKey?: string;
  uploadImageStatus: null | "loading" | "succeeded" | "failed";
  uploadImageError?: any;
}

const initialState: AdminSkillState = {
  value: [],
  fetchingStatus: null,
  addStatus: null,
  updateStatus: null,
  uploadImageStatus: null,
  selected: null,
  sortStatus: null,
};

const fetchSkill = createAsyncThunk(FETCH_SKILL, async () => {
  const res = await axios.get("/admin/resume/skill");
  return res.data;
});

const uploadImageAction = createAsyncThunk(
  UPDATE_LOADING_IMAGE,
  async (file: any) => {
    const {
      data: { url, key },
    } = await axios.post("/s3-storage/presigned-url", {
      contentType: file.type,
      extension: file.name.split(".").pop(),
    });

    await axiosDefault.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    const signedUrl = await axios.post("/s3-storage/signed-url", { key });
    return {
      signedUrl: signedUrl.data.url,
      key,
    };
  }
);

const updateSkills = createAsyncThunk(UPDATE_SKILL, async (data: any) => {
  if (data.sortIndex) {
    data.sortIndex = parseInt(data.sortIndex);
  }
  const response = await axios.put(`/admin/resume/skill/${data.id}`, {
    ...data,
    periodTo: data.periodTo ? data.periodTo : null,
    periodStart: data.periodStart ? data.periodStart : null,
  });
  return response.data;
});

const addSkill = createAsyncThunk(ADD_SKILL, async (payload: any) => {
  delete payload.id;
  if (payload.sortIndex) {
    payload.sortIndex = parseInt(payload.sortIndex);
  }
  const response = await axios.post(`/admin/resume/skill`, {
    ...payload,
    periodTo: payload.periodTo ? payload.periodTo : null,
    periodStart: payload.periodStart ? payload.periodStart : null,
  });
  return response.data;
});

const sortSkillAction = createAsyncThunk(
  DELETE_SKILL,
  async (skill: Skill, type: any) => {
    await axios.put(`/admin/resume/skill/${skill.id}/sort`, {
      type,
    });
  }
);

const deleteSkillAction = createAsyncThunk(
  DELETE_SKILL,
  async (skill: Skill) => {
    const response = await axios.delete(`/admin/resume/skill/${skill?.id}`);
    return response.data;
  }
);

export const adminSkillSlice = createSlice({
  name: "admin-skill-page",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSkill.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = null;
      })
      .addCase(fetchSkill.fulfilled, (state, action) => {
        state.fetchingStatus = "succeeded";
        state.value = action.payload;
        state.fetchingError = null;
      })
      .addCase(fetchSkill.rejected, (state, action) => {
        state.fetchingStatus = "failed";
        state.value = [];
        state.fetchingError = action.error;
      })
      .addCase(addSkill.pending, (state) => {
        state.addStatus = "loading";
        state.addError = null;
      })
      .addCase(addSkill.fulfilled, (state, action) => {
        state.addStatus = "succeeded";
        state.addError = null;
      })
      .addCase(addSkill.rejected, (state, action) => {
        state.addStatus = "failed";
        state.addError = action.error;
      })
      .addCase(updateSkills.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(updateSkills.fulfilled, (state, action) => {
        state.updateError = null;
        state.updateStatus = "succeeded";
      })
      .addCase(updateSkills.rejected, (state, action) => {
        state.updateError = action.error;
        state.updateStatus = "failed";
      })
      .addCase(sortSkillAction.pending, (state) => {
        state.sortStatus = "loading";
        state.sortError = null;
      })
      .addCase(sortSkillAction.fulfilled, (state, action) => {
        state.sortStatus = "succeeded";
        state.sortError = null;
      })
      .addCase(sortSkillAction.rejected, (state, action) => {
        state.sortError = action.error;
        state.sortStatus = "failed";
      })
      .addCase(uploadImageAction.pending, (state) => {
        state.uploadImageStatus = "loading";
        state.uploadImageError = null;
      })
      .addCase(uploadImageAction.fulfilled, (state, action) => {
        state.uploadedSignedUrl = action.payload?.signedUrl;
        state.uploadImageKey = action.payload?.key;
        state.uploadImageError = null;
        state.uploadImageStatus = "succeeded";
      })
      .addCase(uploadImageAction.rejected, (state, action) => {
        state.updateError = action.error;
        state.updateStatus = "failed";
        state.uploadImageKey = undefined;
      });
  },
});

export const adminSkillActions = adminSkillSlice.actions;
export default adminSkillSlice.reducer;
export {
  fetchSkill,
  uploadImageAction,
  updateSkills,
  addSkill,
  deleteSkillAction,
};
