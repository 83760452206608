import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { auth } from "../../configs/firebase.config";
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AppLogo } from "../../common/components/logo";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { appActions, selectIsAuth } from "../../redux/reducers/app.reducer";
import GooleImage from "../../assets/google.png";
import { IconButton } from "@mui/material";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

function BoxContainer(props: any) {
  return (
    <Box
      sx={{
        marginTop: "24px",
        paddingLeft: "18px",
        paddingRight: "18px",
      }}
    >
      {props.children}
    </Box>
  );
}

export function SignInPage() {
  const isAuth = useAppSelector(selectIsAuth);
  const dispatch = useAppDispatch();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [navigateNow, setNavigateNow] = useState<boolean>(false);
  const onSubmit = async ({ email, password }: any) => {
    await setPersistence(auth, browserLocalPersistence);
    if (email && password) {
      setIsLoading(true);
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential: any) => {
          localStorage.setItem("token", userCredential.user.accessToken);
          dispatch(appActions.setAuth(true));
          dispatch(appActions.setUserId(userCredential.user.uid));
          localStorage.setItem("userId", userCredential.user.uid);
          setIsLoading(false);
        })
        .catch((error) => {
          dispatch(appActions.setAuth(false));
          dispatch(appActions.setUserId(null));
          alert("Invalid email or password!");
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(appActions.setAuth(true));
    }
  }, []);

  const loginWithGoogle = async () => {
    try {
      setIsLoading(true);
      const provider = new GoogleAuthProvider();
      await setPersistence(auth, browserLocalPersistence);

      provider.setCustomParameters({
        prompt: "select_account",
      });

      signInWithPopup(auth, provider)
        .then((result: any) => {
          if (result?.user?.accessToken) {
            localStorage.setItem("token", result?.user?.accessToken);
            dispatch(appActions.setUserId(result.user.uid));
            localStorage.setItem("userId", result?.user?.uid);
            dispatch(appActions.setAuth(true));
            setIsLoading(false);
          } else {
            dispatch(appActions.setAuth(false));
            alert("There is an error, please try again!");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          dispatch(appActions.setAuth(false));
          dispatch(appActions.setUserId(null));
          alert(error.message);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isAuth && localStorage.getItem("token")) {
      setNavigateNow(true);
    }
  }, [localStorage.getItem("token"), isAuth]);

  return navigateNow === true ? (
    <Navigate to="/chat" replace={true} />
  ) : (
    <Box
      sx={{
        background: "#dfdfdf",
        width: "100%",
        position: "absolute",
        height: "100vh",
      }}
    >
      {isLoading && <LinearProgress />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          background: "#ffffff",
          margin: "auto",
          padding: "24px",
          width: "500px",
          marginTop: "40px",
          height: "90vh",
          borderRadius: "12px",
          boxSizing: "border-box",
          textAlign: "center",
          paddingLeft: "24px",
          paddingRight: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <AppLogo />
          <Box
            style={{
              paddingLeft: "18px",
              paddingRight: "18px",
              marginTop: "40px",
            }}
          >
            <Box
              sx={{
                marginTop: "24px",
              }}
            >
              <TextField
                sx={{
                  width: "100%",
                }}
                placeholder="Email"
                {...register("email")}
              />
            </Box>
            <Box
              sx={{
                marginTop: "24px",
              }}
            >
              <TextField
                sx={{
                  width: "100%",
                }}
                type="password"
                placeholder="Password"
                {...register("password")}
              />
            </Box>
          </Box>
          <BoxContainer>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                padding: "12px",
              }}
              type="submit"
            >
              SIGN IN
            </Button>

            <Button
              variant="outlined"
              sx={{
                width: "100%",
                padding: "12px",
                marginTop: "12px",
              }}
            >
              SIGN UP!
            </Button>
          </BoxContainer>
          <BoxContainer>
            <Box
              sx={{
                textAlign: "right",
              }}
            >
              <Typography>Forgot your password?</Typography>
            </Box>
          </BoxContainer>
          <Box
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
              marginTop: "12px",
              textAlign: "center",
            }}
          >
            <span>Or connect with</span>
            <IconButton onClick={loginWithGoogle} className="ml-2">
              <img
                src={GooleImage}
                alt="Google login"
                style={{ width: "24px" }}
              />
            </IconButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
