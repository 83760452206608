import { useAppDispatch, useAppSelector } from ".";
import { appActions } from "../reducers/app.reducer";
import { RootState } from "../store";

export function useAppLoading() {
  // no more use, use each action directly
  const appLoading = useAppSelector((state: RootState) => state.app.appLoading);
  const dispatch = useAppDispatch();
  const setAppLoading = (status: boolean) => {
    dispatch(appActions.setAppLoading(status));
  };

  return { setAppLoading, appLoading };
}
