import { getImage } from "../../../../utils/get-image";
import {
  calculateDuration,
  formatDateString,
} from "../../../../utils/format-date";
import { Education } from "../entities/education.entity";

export interface EducationSectionProps {
  educations: Education[];
  selected?: Education | null;
  onSelectEducation?: (education: Education) => void;
}

export function EducationListSection({
  educations,
  selected,
  onSelectEducation,
}: EducationSectionProps) {
  return (
    <>
      <div className="p-3">
        {educations.map((education, index) => {
          const isSelected = selected && selected.id === education.id;
          return (
            <div
              key={`education-${index}`}
              className="flex items-center justify-start mt-10 w-full select-none cursor-pointer edu-item pl-3"
              onClick={() => onSelectEducation && onSelectEducation(education)}
              style={{
                ...(isSelected ? { background: "#005313" } : {}),
              }}
            >
              <div className="education-item" style={{ width: "120px" }}>
                <img
                  src={getImage(education.schoolLogoPublicUrl)}
                  alt="company-logo"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div
                className="p-3"
                style={{
                  width: "100%",
                  ...(isSelected ? { color: "#ffffff" } : {}),
                }}
              >
                <p className="font-sans text-justify font-bold text-xl">
                  {education.studyTitle}
                </p>
                <p className="font-sans text-justify text-xl">
                  {education.schoolName}
                </p>
                <p className="font-sans text-justify text-base font-extralight">
                  {formatDateString(
                    education.periodStart,
                    education.periodCurrent
                  )}{" "}
                  -{" "}
                  {formatDateString(
                    education.periodTo,
                    education.periodCurrent
                  )}{" "}
                  ·{" "}
                  {calculateDuration(
                    education.periodStart,
                    education.periodTo,
                    education.unit as "months" | "years",
                    education.periodCurrent
                  )}{" "}
                  {education.unit}
                </p>
                <p className="font-sans text-justify text-base font-extralight">
                  {education.schoolLocation}
                </p>
                <p className="font-sans text-justify text-base font-extralight">
                  Sort Index: {education.sortIndex ?? "Not Set"}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
