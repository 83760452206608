import { DashboardLayout } from "../layout/dashboard-layout";
import {
  useAppDispatch,
  useAppSelector,
  useSuccessMessage,
} from "../../../redux/hooks";
import { useEffect, useState } from "react";
import { Role } from "./entities/role.entity";
import { RoleTable } from "./components";
import { useForm } from "react-hook-form";
import { PermissionTable } from "./components/permission-table";
import {
  addRoleAction,
  addRolePermissionAction,
  addUserToRoleActions,
  adminRoleActions,
  deleteUserFromRole,
  fetchPermissionAction,
  fetchRoleActions,
  fetchRolePermissionAction,
  fetchSearchUsersActions,
  fetchUsersByRoleCode,
  removeRole,
  removeRolePermissionAction,
} from "../../../redux/reducers/admin/role.reducer";
import { useAppMultipleLoading } from "../../../redux/hooks/use-app-multipe-loading";
import { RolePermission } from "../permissions/entities/permission.entity";
import { UserTable } from "./components/user-table";
import { UserTableForAdding } from "./components/user-table-for-adding";
import { User } from "../../user/entities/user.entity";

export function RolePage() {
  const setSuccessMessage = useSuccessMessage();
  const [searchInput, setSearchInput] = useState("");

  const { register, handleSubmit } = useForm();
  const dispatch = useAppDispatch();
  const {
    permissions,
    rolePermissions,
    value: roles,
    selected,
    fetchRoleStatus,
    fetchPermissionStatus,
    fetchRolePermissionStatus,
    fetchUserStatus,
    deleteUserFromRoleStatus,
    users,
    searchUsers,
    addUserToRoleStatus,
    fetchSearchUserStatus,
  } = useAppSelector((state) => state.roles);

  useAppMultipleLoading([
    fetchRoleStatus.status,
    fetchPermissionStatus.status,
    fetchRolePermissionStatus.status,
    fetchUserStatus.status,
    deleteUserFromRoleStatus.status,
    fetchSearchUserStatus.status,
    addUserToRoleStatus.status,
  ]);

  const getRoles = async () => {
    dispatch(fetchRoleActions());
  };

  const removeUserFromRole = async (user: any) => {
    if (user && user.id && selected && selected.code) {
      await dispatch(
        deleteUserFromRole({
          roleCode: selected?.code,
          userId: user.id,
        })
      );
      dispatch(fetchUsersByRoleCode(selected.code));
      setSuccessMessage("User removed successfully");
    }
  };

  const getPermissions = async () => {
    dispatch(fetchPermissionAction());
  };

  useEffect(() => {
    getRoles();
    getPermissions();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(fetchSearchUsersActions({ search: searchInput }));
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchInput]);

  const onAddRole = async (data: any) => {
    await dispatch(addRoleAction(data));
    await getRoles();
    setSuccessMessage("Role added successfully");
  };

  const onRemoveRole = async (role: Role | null) => {
    if (role && role.code) {
      await dispatch(removeRole(role.code));
      await getRoles();
      handleSetSelectedRole(null);
      dispatch(adminRoleActions.emptyRolePermissions());
      setSuccessMessage("Role removed successfully");
    }
  };

  const handleSetSelectedRole = async (role: Role | null) => {
    if (role && role.code) {
      dispatch(adminRoleActions.setSelected(role));
      refreshRolePermission(role);
      loadUserInRoles(role);
    }
    if (!role) {
      dispatch(adminRoleActions.setSelected(null));
    }
  };

  const handleAddUserToRole = async (user: User) => {
    if (user && user.id && selected && selected.code) {
      await dispatch(
        addUserToRoleActions({
          userId: user.id,
          roleCode: selected.code,
        })
      );
      await loadUserInRoles(selected);
      setSuccessMessage("User added successfully");
    }
  };

  const refreshRolePermission = async (role?: Role | null) => {
    if (role && role.code) {
      const { payload: rolePers } = await dispatch(
        fetchRolePermissionAction(role.code)
      );
      dispatch(
        adminRoleActions.mapRolePermission({
          permissions,
          rolePermissions: rolePers,
        })
      );
    }
  };

  const handleAddRolePermission = async () => {
    const selectedPayload = rolePermissions.filter(
      (item) => item.isSelected === true
    );
    await dispatch(
      addRolePermissionAction({
        roleCode: selected?.code,
        codes: selectedPayload.map((item) => item.code),
      })
    );
    await refreshRolePermission(selected);
  };

  const handleRemovePermission = async () => {
    const selectedPayload = rolePermissions.filter(
      (item) => item.isSelected === true
    );
    await dispatch(
      removeRolePermissionAction({
        roleCode: selected?.code,
        codes: selectedPayload.map((item) => item.code),
      })
    );
    await refreshRolePermission(selected);
  };

  const handleSelectPermission = async (permission: RolePermission) => {
    if (permission) {
      dispatch(adminRoleActions.selectRolePermission(permission));
    }
  };

  const loadUserInRoles = async (role: Role) => {
    if (role && role.code) {
      dispatch(fetchUsersByRoleCode(role.code));
    }
  };

  return (
    <DashboardLayout>
      <div className="grid grid-cols-12 gap-4 p-4">
        <div className="col-span-6">
          <div>
            <p className="text-2xl font-bold text-slate-800 pb-3">Roles</p>
            <RoleTable
              roles={roles}
              removeRole={onRemoveRole}
              setSelectedRole={handleSetSelectedRole}
              selectedRole={selected}
            />
          </div>
          <form onSubmit={handleSubmit(onAddRole)}>
            <p className="text-xl font-bold text-slate-800 mt-8">New Roles</p>
            <div className="role-input-container">
              <div className="mt-3">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Type role code"
                  {...register("code")}
                />
              </div>
              <div className="mt-3">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Type role title"
                  {...register("title")}
                />
              </div>
              <div className="mt-3">
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Type role description (optional)"
                  rows={3}
                  {...register("description")}
                />
              </div>
            </div>
            <div className="role-button-container mt-3 text-right">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2 min-w-[200px]"
              >
                {"Save"}
              </button>
            </div>
          </form>
        </div>
        <div className="col-span-6">
          <div className="role-permission-table-container">
            <div className="flex">
              <p className="text-2xl font-bold text-slate-800 pb-3">
                Roles's Permissions
              </p>
              <div>
                <span className="ml-2 bg-green-600 px-2 py-1 rounded-2xl text-blue-50">
                  {selected?.code}
                </span>
              </div>
            </div>
            <div className="min-h-[30vh]">
              {rolePermissions.length > 0 && (
                <div>
                  <PermissionTable
                    permissions={rolePermissions}
                    handleSelect={handleSelectPermission}
                  />
                  <div className="mt-3 text-right">
                    <button
                      type="button"
                      className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
                      onClick={handleRemovePermission}
                    >
                      {"Remove"}
                    </button>
                    <button
                      type="button"
                      className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
                      onClick={handleAddRolePermission}
                    >
                      {"Add New"}
                    </button>
                  </div>
                </div>
              )}

              {!rolePermissions.length &&
                fetchRolePermissionStatus.status === "succeeded" && (
                  <p>There is no Permission</p>
                )}
              {!selected && <p>Please select a role to view permissions</p>}
            </div>
            <div>
              <div>
                <div className="flex">
                  <p className="text-2xl font-bold text-slate-800 pb-3">
                    List Users In Roles
                  </p>
                </div>
                <div className="mt-3">
                  <UserTable
                    users={users}
                    removeUserFromRole={removeUserFromRole}
                  />
                </div>
                <p className="text-xl font-bold text-slate-800 pb-3 mt-3">
                  All Users
                </p>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Search User By Email or Name"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="mt-3">
                  <UserTableForAdding
                    users={searchUsers}
                    addUserToRole={handleAddUserToRole}
                    selectedRole={selected}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
