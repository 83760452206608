import { getImage } from "../../../../utils/get-image";
import { Experience } from "../../experience/entities/experience.entity";
import { Education } from "../entities/education.entity";

export interface EducationProps {
  register?: any;
  isLoading?: boolean;
  selected?: Education | null;
  onAddNewEducation?: () => void;
  onClearInput?: () => void;
  onDeleteEducation?: (experience?: Education | null) => void;
  sort?: (
    edu: Education | null | undefined,
    type: "decreaseIndex" | "increaseIndex"
  ) => void;
  uploadedUrl?: string;
  uploadingImage?: boolean;
}

export function EducationSection({
  register,
  isLoading,
  onAddNewEducation,
  onClearInput,
  selected,
  onDeleteEducation,
  sort,
  uploadedUrl,
  uploadingImage,
}: EducationProps) {
  return (
    <>
      <p className="text-2xl font-bold text-slate-800">Education</p>
      <div>
        <div className="mt-3 text-left">
          <p className="italic">Choose a image</p>
          <div className="flex justify-between items-center">
            <div>
              <input type="file" {...register("image")} />
            </div>
            {uploadingImage && <p>Uploading...</p>}
            <div>
              <img src={getImage(uploadedUrl)} className="h-12 w-12" />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p className="italic">Study Title</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your title"
            {...register("studyTitle")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">School Name</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your school name"
            {...register("schoolName")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">School Url</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your school url"
            {...register("schoolUrl")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Location</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your data"
            {...register("schoolLocation")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Time Unit</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your data"
            {...register("unit")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Since Period</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your period start"
            {...register("periodStart")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">To Period</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your period to"
            {...register("periodTo")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Sort Index</p>
          <button
            type="button"
            disabled={isLoading}
            className="bg-slate-600 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded active:translate-y-1"
            onClick={() => sort && sort(selected, "increaseIndex")}
          >
            {"Go to end"}
          </button>
          <button
            type="button"
            disabled={isLoading}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
            onClick={() => sort && sort(selected, "decreaseIndex")}
          >
            {"Go to first"}
          </button>
        </div>
        <div className="mt-3"></div>
        <div className="mt-3 text-right flex item-center">
          <p className="italic">Now working here?</p>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-600 ml-2"
            {...register("periodCurrent")}
          />
        </div>
        <div className="mt-3 text-right hidden">
          <p className="italic">ID</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your period to"
            {...register("id")}
          />
          <input type="text" {...register("schoolLogoUrl")} />
        </div>
        <div className="mt-3 text-right">
          <button
            type="button"
            disabled={isLoading}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
            onClick={() => onDeleteEducation && onDeleteEducation(selected)}
          >
            {"Remove"}
          </button>
          <button
            type="button"
            disabled={isLoading}
            className="bg-slate-600 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
            onClick={onClearInput}
          >
            {"Clear Input"}
          </button>
          <button
            type="button"
            disabled={isLoading}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
            onClick={onAddNewEducation}
          >
            {"Add New"}
          </button>

          <button
            type="submit"
            disabled={isLoading}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
          >
            {!isLoading ? "Update" : "Updating..."}
          </button>
        </div>
      </div>
    </>
  );
}
