import { useEffect, useState } from "react";
import { axios } from "../../../configs/axios.config";
import { DashboardLayout } from "../layout/dashboard-layout";
import moment from "moment";
import { useAppLoading } from "../../../redux/hooks";

export function HomeDashboardPage() {
  const { appLoading, setAppLoading } = useAppLoading();
  const [serverMessage, setServerMessage] = useState<string>("");
  const [latestPing, setLatestPing] = useState<any>();
  const getLatestPing = async () => {
    try {
      setAppLoading(true);
      const response = await axios.get("/server-logs/latest-ping");
      setAppLoading(false);
      setLatestPing(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLatestPing();
  }, []);

  useEffect(() => {
    if (appLoading) {
      setServerMessage("Checking server status...");
    } else if (latestPing) {
      setServerMessage(
        `Online (Latest ping ${moment(latestPing?.createdAt).fromNow()})`
      );
    }
  }, [latestPing, appLoading]);

  return (
    <DashboardLayout>
      <div className="flex items-center">
        <span className="h-3 w-3 bg-green-500 rounded-full mr-2"></span>
        <div>{serverMessage}</div>
      </div>
    </DashboardLayout>
  );
}
