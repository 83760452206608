import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../configs/axios.config";
import { FETCH_LOGS } from "../../types";

interface AdminLogsState {
  value: any[];
  fetchingStatus: null | "loading" | "succeeded" | "failed";
  fetchingError?: any;
}

const initialState: AdminLogsState = {
  value: [],
  fetchingStatus: null,
};

const fetchLogs = createAsyncThunk(FETCH_LOGS, async (params: string = "") => {
  const res = await axios.get(`/admin/logger${params}`);
  return res.data;
});

export const adminAboutMeSlice = createSlice({
  name: "admin-logs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = null;
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        state.fetchingStatus = "succeeded";
        state.value = action.payload;
        state.fetchingError = null;
      })
      .addCase(fetchLogs.rejected, (state, action) => {
        state.fetchingStatus = "failed";
        state.value = [];
        state.fetchingError = action.error;
      });
  },
});

export const adminAboutMeActions = adminAboutMeSlice.actions;
export default adminAboutMeSlice.reducer;
export { fetchLogs };
