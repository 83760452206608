import { Box, Typography } from "@mui/material";

type Props = {
  showDescription?: boolean;
};

export function AppLogo({ showDescription }: Props) {
  return (
    <Box>
      <p
        style={{
          fontWeight: "bold",
          fontFamily: "-apple-system BlinkMacSystemFont",
        }}
        className="text-3xl"
      >
        ThanhPhanV
      </p>
      {(showDescription === undefined || showDescription === true) && (
        <Typography
          sx={{
            fontWeight: "400",
          }}
        >
          Welcome to my personal website!
        </Typography>
      )}
    </Box>
  );
}
