export const EVENT_AUTHENTICATE = "authenticate";
export const EVENT_CONVERSATION_JOIN = "conversation/join";
export const EVENT_CONVERSATION_CLIENT_SEND_MESSAGE =
  "conversation/client/send-message";

export const EVENT_CONVERSATION_SERVER_SEND_MESSAGE =
  "conversation/server/send-message";

export const EVENT_CONVERSATION_CLIENT_READ =
  "conversation/client/read-message";

export const EVENT_CONVERSATION_SERVER_READ =
  "conversation/server/read-message";

export const EVENT_CLIENT_MESSAGE_TYPING = "conversation/client/typing-message";

export const EVENT_SERVER_MESSAGE_TYPING = "conversation/server/typing-message";

export const EVENT_CLIENT_MESSAGE_STOP_TYPING =
  "conversation/client/stop-typing-message";

export const EVENT_SERVER_MESSAGE_STOP_TYPING =
  "conversation/server/stop-typing-message";

export const EVENT_SERVER_SEND_CONVERSATION_CHANGE =
  "conversation/server/conversation-update";
