import { User } from "../../../../types/user";
import { Role } from "../entities/role.entity";

export interface UserTableProps {
  users: User[];
  selectedRole: Role | null;
  addUserToRole: (user: User) => void;
}

export function UserTableForAdding({
  users,
  addUserToRole,
  selectedRole,
}: UserTableProps) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full divide-y divide-gray-200 overflow-hidden">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user) => {
            let trClass =
              "select-none cursor-pointer bg-gray-50 hover:bg-gray-100";
            let buttonClass =
              "bg-gray-600 text-white font-bold py-2 px-4 ml-2 rounded";
            if (selectedRole) {
              buttonClass = buttonClass
                .concat("hover:bg-green-700 active:translate-y-1")
                .replace("bg-gray-600", "bg-green-600");
            }
            return (
              <tr key={user.id} className={trClass}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.id?.slice(-7)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.firstName} {user.lastName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    type="button"
                    className={buttonClass}
                    onClick={() => addUserToRole(user)}
                    disabled={!selectedRole}
                  >
                    {`Add To ${selectedRole?.code || ""} `}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
