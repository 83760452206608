import { getImage } from "../../../utils/get-image";
import { User } from "../../user/entities/user.entity";

export interface UserItemProps {
  user: User;
  selected: User | null;
  selectUser: (user: User) => void;
}

export function UserItem({ user, selectUser, selected }: UserItemProps) {
  let nameOfClass = "chat-user-item rounded-3xl flex items-center";
  return (
    <div className={nameOfClass} onClick={() => selectUser(user)}>
      <div className="h-[72px] w-[72px] rounded-full overflow-hidden">
        <img src={getImage("default")} />
      </div>
      <div className="px-4">
        <div className="font-bold font-sans text-base">
          {user?.firstName} {user?.lastName}
        </div>
        <div className="font-sans text-center text-sm">{user?.email || ""}</div>
      </div>
    </div>
  );
}
