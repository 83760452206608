import "../../../css/landing.css";
export function Footer(aboutMe: any) {
  return (
    <footer className="footer-container text-white p-5 footer">
      <div className="flex justify-center items-center">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-bold mb-3">Contact Me</h2>
          <div className="flex items-center font-thin italic mb-2">
            <span>Email: {aboutMe?.email}</span>
          </div>
          <div className="flex items-center font-thin italic mb-2">
            <span>Phone: {aboutMe?.phone}</span>
          </div>
          <div className="flex items-center font-thin italic mb-2">
            <span>
              Thank you for visiting{" "}
              <a
                href="https://drive.google.com/drive/folders/1c4j2DhpuwLDYBOg5cWVszeeE9W-NFO9m?usp=sharing"
                target="blank"
              >
                my page!
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
