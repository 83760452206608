import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from ".";
import { appActions } from "../reducers/app.reducer";

export function useSuccessMessage() {
  const dispatch = useAppDispatch();
  const successMessage = useAppSelector((state) => state.app.successMessage);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        dispatch(appActions.setSuccessMessage(""));
      }, 2000);

      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [successMessage, dispatch]);

  const setSuccessMessage = (message: string) => {
    dispatch(appActions.setSuccessMessage(message));
  };

  return setSuccessMessage;
}
