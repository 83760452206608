import { User } from "../../../types/user";
import { formatRelativeDateForMessage } from "../../../utils/format-date";
import { getImage } from "../../../utils/get-image";
import { Message } from "../entities/message.entity";

export interface MessageItemProps {
  message: Message;
  myId?: string | null;
  friend?: User;
  isRead?: boolean;
  isLastMessage?: boolean;
}

export function MessageItem({
  message,
  myId,
  friend,
  isRead,
  isLastMessage,
}: MessageItemProps) {
  let nameOfClass = "rounded-3xl flex items-center chat-message-item mt-3";
  let nameOfClassChatBody =
    "chat-message-body rounded-3xl flex items-center justify-between p-2";
  const isMe = message.userId === myId;
  let nameOfClassTimer = "text-right font-extralight italic text-sm px-5";
  if (isMe) {
    nameOfClass += " justify-end";
    nameOfClassChatBody += " chat-message-body-right";
    nameOfClassTimer += " text-gray-400";
  } else {
    nameOfClassChatBody += " chat-message-body-left";
    nameOfClassTimer += " text-gray-500";
  }
  return (
    <div className="">
      <div className={nameOfClass}>
        {!isMe && (
          <div className="h-[48px] w-[48px] rounded-full overflow-hidden">
            <img
              src={getImage(friend?.imageUrl || "default")}
              className="h-[48px] w-[48px] rounded-full"
              alt="avatar-message-item"
            />
          </div>
        )}
        <div className="w-[60%] ">
          <div className={nameOfClassChatBody}>
            <div className="min-h-[2rem] flex flex-col justify-center">
              {!isMe && (
                <div className="font-sans text-sm font-bold">
                  {friend?.firstName} {friend?.lastName}
                </div>
              )}
              <div className="font-sans text-sm text-justify">
                {message.body}
              </div>
            </div>
          </div>
          <div className={nameOfClassTimer}>
            {" "}
            {isRead && isMe && "Seen - "}
            {formatRelativeDateForMessage(message.updatedAt)}
          </div>
        </div>
      </div>
    </div>
  );
}
