import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../configs/axios.config";
import { User } from "../../../types/user";
import { FetchingStatus } from "../../../common/types/app-type";
import { genActionType } from "../../../utils";
import axiosDefault from "axios";

interface FetchUserResponse {
  users: User[];
  page: number;
}

interface ChatUsersState {
  value: FetchUserResponse;
  profile?: User;
  selected: User | null;
  fetchingProgress: {
    // fetching list of users
    status: FetchingStatus;
    error?: any;
  };
  fetchingUserProfileProgress: {
    status: FetchingStatus;
    error?: any;
  };
  updateProfileProgress: {
    status: FetchingStatus;
    error?: any;
  };
  uploadedSignedUrl?: string;
  uploadImageKey?: string;
  uploadImageStatus: null | "loading" | "succeeded" | "failed";
  uploadImageError?: any;
}

const initialState: ChatUsersState = {
  value: {
    users: [],
    page: 1,
  },
  fetchingProgress: {
    status: FetchingStatus.Init,
    error: null,
  },
  fetchingUserProfileProgress: {
    status: FetchingStatus.Init,
    error: null,
  },
  updateProfileProgress: {
    status: FetchingStatus.Init,
    error: null,
  },
  selected: null,
  uploadImageStatus: null,
};

const fetchUserAction = createAsyncThunk(
  genActionType("chat", "fetch-user"),
  async (payload: any) => {
    const { search } = payload;
    const res = await axios.get("/chat/user", {
      params: {
        search,
      },
    });
    return res.data;
  }
);

const fetchUserProfileAction = createAsyncThunk(
  genActionType("chat", "fetch-user-profile"),
  async () => {
    const res = await axios.get("/user-profile");
    return res.data;
  }
);

const uploadImageActions = createAsyncThunk(
  genActionType("chat", "upload-image"),
  async (file: any) => {
    const {
      data: { url, key },
    } = await axios.post("/s3-storage/presigned-url", {
      contentType: file.type,
      extension: file.name.split(".").pop(),
    });

    await axiosDefault.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    const signedUrl = await axios.post("/s3-storage/signed-url", { key });
    return {
      signedUrl: signedUrl.data.url,
      key,
    };
  }
);

const updateProfileAction = createAsyncThunk(
  genActionType("chat", "update-profile"),
  async (payload: any) => {
    const res = await axios.put("/user-profile", payload);
    return res.data;
  }
);

export const chatPageSlice = createSlice({
  name: "chat-page",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAction.pending, (state) => {
        state.fetchingProgress = {
          status: FetchingStatus.Loading,
          error: null,
        };
      })
      .addCase(fetchUserAction.fulfilled, (state, action) => {
        state.fetchingProgress = {
          status: FetchingStatus.Succeeded,
          error: null,
        };
        state.value = action.payload;
      })
      .addCase(fetchUserAction.rejected, (state, action) => {
        state.fetchingProgress = {
          status: FetchingStatus.Failed,
          error: action.error,
        };
      })
      .addCase(fetchUserProfileAction.pending, (state) => {
        state.fetchingUserProfileProgress = {
          status: FetchingStatus.Loading,
          error: null,
        };
      })
      .addCase(fetchUserProfileAction.fulfilled, (state, action) => {
        state.fetchingUserProfileProgress = {
          status: FetchingStatus.Succeeded,
          error: null,
        };
        state.profile = action.payload;
        state.uploadedSignedUrl = action.payload?.imageUrl;
      })
      .addCase(fetchUserProfileAction.rejected, (state, action) => {
        state.fetchingUserProfileProgress = {
          status: FetchingStatus.Failed,
          error: action.error,
        };
      })
      .addCase(uploadImageActions.pending, (state) => {
        state.uploadImageStatus = "loading";
        state.uploadImageError = null;
        state.uploadedSignedUrl = undefined;
        state.uploadImageKey = undefined;
      })
      .addCase(uploadImageActions.fulfilled, (state, action) => {
        state.uploadedSignedUrl = action.payload?.signedUrl;
        state.uploadImageKey = action.payload?.key;
        state.uploadImageError = null;
        state.uploadImageStatus = "succeeded";
      })
      .addCase(uploadImageActions.rejected, (state, action) => {
        state.uploadImageStatus = "failed";
        state.uploadedSignedUrl = undefined;
        state.uploadImageKey = undefined;
      })
      .addCase(updateProfileAction.pending, (state) => {
        state.fetchingUserProfileProgress = {
          status: FetchingStatus.Loading,
          error: null,
        };
      })
      .addCase(updateProfileAction.fulfilled, (state, action) => {
        state.fetchingUserProfileProgress = {
          status: FetchingStatus.Succeeded,
          error: null,
        };
        state.profile = action.payload;
      })
      .addCase(updateProfileAction.rejected, (state, action) => {
        state.fetchingUserProfileProgress = {
          status: FetchingStatus.Failed,
          error: action.error,
        };
      });
  },
});

export const chatActions = chatPageSlice.actions;
export default chatPageSlice.reducer;
export {
  fetchUserAction,
  fetchUserProfileAction,
  uploadImageActions,
  updateProfileAction,
};
