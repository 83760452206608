import { useAppSelector } from "../../../redux/hooks";
import { IconButton, LinearProgress } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { MenuPaths } from "./menu-path";
import ChatIcon from "@mui/icons-material/Chat";

import "../css/index.css";
import { useLogout } from "../../../hooks/use-logout";
import { MenuItem } from "./menu-item";
import { useLocation, useNavigate } from "react-router-dom";

export function DashboardLayout({ children }: any) {
  const { appLoading } = useAppSelector((state: any) => state.app);
  const { handleSignout } = useLogout();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="user-features-container flex flex-col overflow-hidden">
      {appLoading && (
        <div className="fixed top-0 z-20 w-full">
          <LinearProgress />
        </div>
      )}
      <div className="fixed w-full top-0 left-0 border-box">
        <div className="h-[48px] chat-header border-box">
          <div className="grid grid-cols-12 h-full border-box">
            <div className="col-span-3 flex items-center">
              <h6 className="text-2xl logo border-box font-bold font-sans flex items-center ml-3">
                ThanhPhanV
              </h6>
            </div>
            <div className="col-span-9">
              <div className="flex items-center justify-end h-full mr-3">
                <div>
                  <IconButton onClick={() => navigate("/chat")}>
                    <ChatIcon />
                  </IconButton>
                </div>
                <div>
                  <IconButton onClick={handleSignout}>
                    <LogoutIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 border-box flex-grow mt-[48px] overflow-y-scroll chat-scrollbar-hide">
        <div className="col-span-3">
          <div className="col-span-2 box-border dashboard-site-menu">
            <div className="col-span-2 box-border dashboard-site-menu">
              {MenuPaths.map((menuItem) => (
                <MenuItem
                  key={menuItem.path}
                  navigate={navigate}
                  path={menuItem.path}
                  name={menuItem.name}
                  currentPath={location.pathname}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-6">{children}</div>
      </div>
    </div>
  );
}
