import { useNavigate } from "react-router-dom";
import { DashboardLayout } from "../layout/dashboard-layout";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState } from "react";
import {
  deleteMonthlyIncomeAction,
  fetchMonthlyBudgetAction,
  fetchMonthlyIncomeAction,
  updateMonthlyIncomeAction,
} from "../../../redux/reducers/admin/expense.reducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useForm } from "react-hook-form";
import { MonthlyIncome } from "./entities/monthly-income.entity";
import * as _ from "lodash";
import { convertSpecialKey } from "../../../utils/convert-special-key";
import { MonthlyBudget } from "./entities/monthly-budget.entity";
import { NewMonthlyIncomeRow } from "./types/new-monthly-income-row.type";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppMultipleLoading } from "../../../redux/hooks/use-app-multipe-loading";
import { formatCurrency } from "../../../utils/currency-format";

export function ExpenseManagementPages() {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    monthlyIncomes,
    monthlyBudgets,
    fetchMonthlyBudgetStatus,
    fetchMonthlyIncomeStatus,
    deleteMonthlyBudgetStatus,
    updateMonthlyBudgetStatus,
  } = useAppSelector((state) => state.expense);
  useAppMultipleLoading([
    fetchMonthlyBudgetStatus.status,
    fetchMonthlyIncomeStatus.status,
    deleteMonthlyBudgetStatus.status,
    updateMonthlyBudgetStatus.status,
  ]);
  const [totalMonthlyIncome, setTotalMonthlyIncome] = useState(0);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const { register, handleSubmit, setValue, unregister, watch } = useForm();
  const [newMonthlyIncomeRow, setNewMonthlyIncomeRow] = useState<
    NewMonthlyIncomeRow[]
  >([]);
  const {
    register: monthlyBudgetRegister,
    handleSubmit: monthlyBudgetHandleSubmit,
    setValue: monthlyBudgetSetvalue,
  } = useForm();

  const fetchMonthlyIncome = async () => {
    const monthlyIncome = await dispatch(
      fetchMonthlyIncomeAction(selectedYear)
    );
    if (monthlyIncome.payload?.length) {
      (monthlyIncome.payload as MonthlyIncome[]).forEach((income) => {
        setValue(`${income.id}:amount`, income.amount);
        setValue(`${income.id}:title`, income.title);
      });
      setTotalMonthlyIncome(
        monthlyIncome?.payload.reduce((acc: number, income: MonthlyIncome) => {
          return acc + income.amount;
        }, 0)
      );
    }
  };

  const fetchMonthlyBudget = async () => {
    const monthlyBudget = await dispatch(
      fetchMonthlyBudgetAction(selectedYear)
    );
    if (monthlyBudget.payload?.length) {
      console.log(monthlyBudget.payload);
      (monthlyBudget.payload as MonthlyBudget[]).forEach((budget) => {
        monthlyBudgetSetvalue(`${budget.id}:dailyAmount`, budget.dailyAmount);
        monthlyBudgetSetvalue(
          `${budget.id}:monthlyAmount`,
          budget.monthlyAmount
        );
      });
    }
  };

  const onMonthlyIncomeSubmit = async (data: any) => {
    const formatPayload = convertSpecialKey(data);
    await dispatch(updateMonthlyIncomeAction(formatPayload));
    handleClearNewIncomeRows();
    fetchMonthlyIncome();
  };

  const onMonthlyBudgetSubmit = async (data: any) => {
    const formatPayload = convertSpecialKey(data);
    console.log(formatPayload);
  };

  const handleAddNewMonthlyIncome = () => {
    const newIncomeRow: NewMonthlyIncomeRow = {
      id: _.uniqueId("new_income_"),
      title: "",
      amount: 0,
      year: selectedYear,
    };
    setNewMonthlyIncomeRow((prev) => [...prev, newIncomeRow]);
  };
  const handleClearNewIncomeRows = () => {
    newMonthlyIncomeRow.forEach((row) => {
      unregister(`${row.id}:title`);
      unregister(`${row.id}:amount`);
    });
    setNewMonthlyIncomeRow([]);
  };

  const handleDeleteMontlyIncome = async (id: string) => {
    await dispatch(deleteMonthlyIncomeAction(id));
    handleClearNewIncomeRows();
    fetchMonthlyIncome();
  };

  useEffect(() => {
    fetchMonthlyIncome();
    fetchMonthlyBudget();
  }, [selectedYear]);

  return (
    <DashboardLayout>
      <div className="flex flex-col p-4">
        <div className="flex justify-between">
          <div className="flex">
            <h3 className="text-2xl font-bold">Expense Management - </h3>
            <select
              id="yearSelect"
              className="bg-transparent outline-none focus:outline-none border text-sm rounded-lg ml-2 px-2 text-red-700 font-bold"
              onChange={(e) => {
                setSelectedYear(parseInt(e.target.value));
              }}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={() => {
              navigate("/admin/expense/overview");
            }}
            className="ml-2 bg-orange-700 text-white px-2 py-1 rounded hover:bg-orange-600 focus:outline-none active:bg-orange-500"
          >
            Go to overview page <ArrowForwardIcon />
          </button>
        </div>
        <div>
          <div>
            {" "}
            <p className="text-lg mt-2 font-bold">Monthly Income</p>
            <p className="bg-yellow-100 text-yellow-800 font-semibold px-2 py-1 rounded">
              {formatCurrency(totalMonthlyIncome)}
            </p>
          </div>
        </div>
        <div className="mt-2">
          <form onSubmit={handleSubmit(onMonthlyIncomeSubmit)}>
            <table className="min-w-full table-auto border-collapse border border-gray-200">
              <thead className="bg-gray-100 font-bold">
                <tr>
                  <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                    From Source
                  </th>
                  <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                    Total
                  </th>
                  <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {monthlyIncomes.map((income) => (
                  <tr key={income.id}>
                    <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                      <input
                        className="bg-transparent outline-none focus:outline-none"
                        placeholder="Monthly salary"
                        {...register(`${income.id}:title`)}
                      />
                    </td>
                    <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                      <input
                        type="number"
                        className="bg-transparent outline-none focus:outline-none min-w-[35%]"
                        placeholder="Monthly salary"
                        {...register(`${income.id}:amount`)}
                      />
                      <span className="ml-2">
                        {formatCurrency(watch(`${income.id}:amount`))}
                      </span>
                    </td>
                    <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-red-700">
                      <IconButton
                        onClick={() => {
                          handleDeleteMontlyIncome(income.id);
                        }}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </td>
                  </tr>
                ))}
                {newMonthlyIncomeRow.map((income) => (
                  <tr key={income.id}>
                    <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-50 bg-green-600">
                      <input
                        className="bg-transparent outline-none focus:outline-none w-full"
                        placeholder="Monthly salary"
                        {...register(`${income.id}:title`)}
                      />
                    </td>
                    <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-50 bg-green-600">
                      <input
                        type="number"
                        className="bg-transparent outline-none focus:outline-none min-w-[35%]"
                        placeholder="Monthly salary"
                        {...register(`${income.id}:amount`)}
                      />
                      <span className="ml-2">
                        {formatCurrency(watch(`${income.id}:amount`))}
                      </span>
                    </td>
                    <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-50 bg-green-600"></td>
                    <td className="hidden border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-50 bg-green-600">
                      <input
                        type="number"
                        placeholder="Monthly salary"
                        {...register(`${income.id}:year`, {
                          value: selectedYear,
                        })}
                      />
                      VND
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="py-3 text-right">
              {newMonthlyIncomeRow.length > 0 && (
                <button
                  className="text-right bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={handleClearNewIncomeRows}
                >
                  Clear Added
                </button>
              )}
              <button
                className="text-right ml-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleAddNewMonthlyIncome}
              >
                Add New
              </button>
              <button className="ml-2 text-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Save Changes
              </button>
            </div>
          </form>
        </div>
        <div>
          <div>
            <p className="text-lg mt-2 font-bold">Monthly Budget</p>
            <p className="bg-yellow-100 text-yellow-800 font-semibold px-2 py-1 rounded">
              ~ 30.000.000 VND
            </p>
          </div>
        </div>
        <div className="mt-2">
          <form onSubmit={monthlyBudgetHandleSubmit(onMonthlyBudgetSubmit)}>
            <table className="min-w-full table-auto border-collapse border border-gray-200">
              <thead className="bg-gray-100 font-bold">
                <tr>
                  <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                    Month
                  </th>
                  <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                    Daily Budget
                  </th>
                  <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {monthlyBudgets.map((monthlyBudget) => (
                  <tr key={monthlyBudget.id}>
                    <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                      January
                    </td>
                    <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                      <input
                        type="number"
                        className="bg-transparent outline-none focus:outline-none"
                        placeholder="Type your budget"
                        {...monthlyBudgetRegister(
                          `${monthlyBudget.id}:dailyAmount`
                        )}
                      />
                      VND
                    </td>
                    <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                      <input
                        type="number"
                        className="bg-transparent outline-none focus:outline-none"
                        placeholder="Type your budget"
                        {...monthlyBudgetRegister(
                          `${monthlyBudget.id}:monthlyAmount`
                        )}
                      />
                      VND
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="py-3 text-right">
              <button className="text-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
}
