import { formatRelativeDateForMessage } from "../../../utils/format-date";
import { getImage } from "../../../utils/get-image";
import { Conversation } from "../entities/conversation.entity";

export interface ConversationItemProps {
  conversation: Conversation;
  selected: Conversation | null;
  selectConversation: (conversation: Conversation) => void;
  loggedInUserId?: string | null;
}

export function ConversationItem({
  conversation,
  selectConversation,
  selected,
  loggedInUserId,
}: ConversationItemProps) {
  let nameOfClass = "chat-user-item rounded-3xl flex items-center";
  if (selected && selected?.id === conversation.id) {
    nameOfClass += " chat-user-item-selected";
  }

  const friend = conversation.users.find((user) => !user.isMe);
  const totalUnread = (conversation?.unreadUsers || []).filter(
    (item) => item === loggedInUserId
  ).length;

  return (
    <div
      className={nameOfClass}
      onClick={() => selectConversation(conversation)}
    >
      <div className="h-[52px] w-[52px] overflow-hidden flex items-center justify-center conversation-avatar-container">
        <img
          src={getImage(friend?.imageUrl || "default")}
          style={{ width: "52px" }}
          alt="avatar"
          className="rounded-full"
        />
      </div>
      <div className="px-4 text-sm w-full flex items-center">
        <div className="w-full">
          <div className="font-bold font-sans text-base">
            {friend?.firstName} {friend?.lastName}
          </div>
          <div className="font-sans text-sm">
            {conversation.lastMessage || "Default last messages!"}
          </div>
        </div>
        <div className="flex items-center justify-center">
          {totalUnread > 0 && (
            <div className="text-red-700 w-5 font-bold">{totalUnread}</div>
          )}
          <div className="font-extralight text-gray-500">
            {formatRelativeDateForMessage(conversation.updatedAt)}
          </div>
        </div>
      </div>
    </div>
  );
}
