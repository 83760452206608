import * as _ from "lodash";

/**
 * Example of usage
 * If object like this
 *    {
 *      "id_1:firstName": "John",
 *      "id_1:lastName": "Doe",
 *      "id_2:firstName": "John",
 *      "id_2:lastName": "Doe",
 *    }
 * It will be converted to
 *    [
 *       {
 *          id: "id_1",
 *          firstName: "John",
 *          lastName: "Doe"
 *       },
 *       {
 *         id: "id_2",
 *         firstName: "John",
 *         lastName: "Doe"
 *       }
 *     ]
 */
export function convertSpecialKey(data: any) {
  console.log(data);
  const payload = Object.entries(data).map(([key, value]) => {
    const [id, field] = key.split(":");
    return {
      id,
      field,
      value,
    };
  });

  const groupById = _.groupBy(payload, "id");
  const payloadToSubmit = Object.entries(groupById).flatMap(([id, fields]) =>
    fields.map(({ field, value }: any) => ({
      [field]: value,
      id,
    }))
  );

  const finalPayload: any = [];
  Object.entries(_.groupBy(payloadToSubmit, "id")).forEach(([id, fields]) => {
    let item: any = { id };
    fields.forEach((itemValue) => {
      item = {
        ...item,
        ...itemValue,
      };
    });
    finalPayload.push(item);
  });

  return finalPayload;
}
