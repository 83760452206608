import { useForm } from "react-hook-form";
import { DashboardLayout } from "../layouts/dashboard-layout";
import { getImage } from "../../../utils/get-image";
import { useEffect } from "react";
import {
  fetchUserProfileAction,
  updateProfileAction,
  uploadImageActions,
} from "../../../redux/reducers/chats/user.reducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { useAppMultipleLoading } from "../../../redux/hooks/use-app-multipe-loading";

export function ProfilePage() {
  const { register, handleSubmit, setValue, watch } = useForm();

  const dispatch = useAppDispatch();
  const {
    profile,
    uploadImageKey,
    uploadedSignedUrl,
    updateProfileProgress,
    fetchingProgress,
    fetchingUserProfileProgress,
  } = useAppSelector((state: RootState) => state.users);
  useAppMultipleLoading([
    updateProfileProgress.status,
    fetchingProgress.status,
    fetchingUserProfileProgress.status,
  ]);
  useEffect(() => {
    dispatch(fetchUserProfileAction());
  }, []);

  useEffect(() => {
    if (uploadImageKey) {
      setValue("imageKey", uploadImageKey);
    }
  }, [uploadImageKey]);

  useEffect(() => {
    const files = watch("image");
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        uploadImage(file);
      }
    }
  }, [watch("image")]);

  const uploadImage = async (file: File) => {
    dispatch(uploadImageActions(file));
  };

  const onSubmit = async (data: any) => {
    dispatch(updateProfileAction(data));
  };

  useEffect(() => {
    if (profile) {
      setValue("firstName", profile.firstName);
      setValue("email", profile.email);
      setValue("lastName", profile.lastName);
      setValue("imageKey", profile.imageKey);
      setValue("imageUrl", profile.imageUrl);
    }
  }, [profile?.id]);

  return (
    <DashboardLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="text-2xl font-bold text-slate-800">Basic Info</p>
        <div>
          <div className="mt-3 text-left">
            <p className="italic">Choose a image</p>
            <div className="flex justify-between items-center">
              <div>
                <input type="file" {...register("image")} />
              </div>
              <div>
                <img
                  src={getImage(uploadedSignedUrl)}
                  alt="something-special"
                  className="h-16 w-16"
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p className="italic">First name</p>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Type your name"
              {...register("firstName")}
            />
          </div>
          <div className="mt-3">
            <p className="italic">Last name</p>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Type your name"
              {...register("lastName")}
            />
          </div>
          <div className="mt-3">
            <p className="italic">Email</p>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Type your email"
              disabled
              {...register("email")}
            />
          </div>
          <div className="hidden">
            <input {...register("imageKey")} />
          </div>
          <div className="mt-3 text-right">
            <button
              disabled={updateProfileProgress.status === "loading"}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded active:translate-y-1"
            >
              {"Updates"}
            </button>
          </div>
        </div>
      </form>
    </DashboardLayout>
  );
}
