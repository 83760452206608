export interface AboutMeProps {
  name?: string;
  objective?: string;
  email?: string;
  phone?: string;
  facebookUrl?: string;
  avatarUrl?: string;
  avatarPublicUrl?: string;
  jobTitle?: string;
}

export function AboutMe({ name, objective, jobTitle }: AboutMeProps) {
  return (
    <>
      <div className="text-2xl md:text-3xl font-bold font-mono text-center p-3">
        <p>--- {name} ---</p>
        <p className="text-base md:text-xl font-extralight text-slate-800 italic">
          {jobTitle}
        </p>
      </div>
      {/* objective */}
      <p className="font-sans text-justify sm:text-center">{objective}</p>
    </>
  );
}
