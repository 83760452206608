import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../configs/axios.config";
import { genActionType } from "../../../utils";
import { MonthlyIncome } from "../../../pages/admin/expenses/entities/monthly-income.entity";
import { MonthlyBudget } from "../../../pages/admin/expenses/entities/monthly-budget.entity";

enum FetchStatus {
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
  NOT_INIT = "not_init",
}

interface AdminExpenseState {
  monthlyIncomes: MonthlyIncome[];
  fetchMonthlyIncomeStatus: {
    status: FetchStatus;
    error?: any;
  };
  monthlyBudgets: MonthlyBudget[];
  fetchMonthlyBudgetStatus: {
    status: FetchStatus;
    error?: any;
  };
  updateMonthlyBudgetStatus: {
    status: FetchStatus;
    error?: any;
  };
  deleteMonthlyBudgetStatus: {
    status: FetchStatus;
    error?: any;
  };
}

const initialState: AdminExpenseState = {
  monthlyIncomes: [],
  fetchMonthlyIncomeStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  monthlyBudgets: [],
  fetchMonthlyBudgetStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  updateMonthlyBudgetStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  deleteMonthlyBudgetStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
};

const fetchMonthlyIncomeAction = createAsyncThunk(
  genActionType("admin/expense", "fetch-monthly-income"),
  async (year: number) => {
    try {
      const res = await axios.get("/admin/expense/monthly-income", {
        params: {
          year,
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

const updateMonthlyIncomeAction = createAsyncThunk(
  genActionType("admin/expense", "update-monthly-income"),
  async (payload: any) => {
    try {
      const res = await axios.put("/admin/expense/monthly-income", payload);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

const deleteMonthlyIncomeAction = createAsyncThunk(
  genActionType("admin/expense", "delete-monthly-income"),
  async (id: any) => {
    try {
      const res = await axios.delete("/admin/expense/monthly-income", {
        params: {
          id,
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

const fetchMonthlyBudgetAction = createAsyncThunk(
  genActionType("admin/expense", "fetch-monthly-budget"),
  async (year: number) => {
    try {
      const res = await axios.get("/admin/expense/monthly-budget", {
        params: {
          year,
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const adminExpenseSlice = createSlice({
  name: "admin-expense-page",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMonthlyIncomeAction.pending, (state) => {
        state.fetchMonthlyIncomeStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(fetchMonthlyIncomeAction.fulfilled, (state, action) => {
        state.monthlyIncomes = action.payload;
        state.fetchMonthlyIncomeStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(fetchMonthlyIncomeAction.rejected, (state, action) => {
        state.monthlyIncomes = [];
        state.fetchMonthlyIncomeStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(fetchMonthlyBudgetAction.pending, (state) => {
        state.fetchMonthlyBudgetStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(fetchMonthlyBudgetAction.fulfilled, (state, action) => {
        state.monthlyBudgets = action.payload;
        state.fetchMonthlyBudgetStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(fetchMonthlyBudgetAction.rejected, (state, action) => {
        state.monthlyBudgets = [];
        state.fetchMonthlyBudgetStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(updateMonthlyIncomeAction.pending, (state) => {
        state.updateMonthlyBudgetStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(updateMonthlyIncomeAction.fulfilled, (state, action) => {
        state.updateMonthlyBudgetStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(updateMonthlyIncomeAction.rejected, (state, action) => {
        state.updateMonthlyBudgetStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(deleteMonthlyIncomeAction.pending, (state) => {
        state.deleteMonthlyBudgetStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(deleteMonthlyIncomeAction.fulfilled, (state, action) => {
        state.deleteMonthlyBudgetStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(deleteMonthlyIncomeAction.rejected, (state, action) => {
        state.deleteMonthlyBudgetStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      });
  },
});

export const adminExpenseActions = adminExpenseSlice.actions;
export default adminExpenseSlice.reducer;
export {
  fetchMonthlyIncomeAction,
  fetchMonthlyBudgetAction,
  updateMonthlyIncomeAction,
  deleteMonthlyIncomeAction,
};
