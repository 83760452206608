export const MenuPaths = [
  { path: "/admin", extraPaths: [], name: "Home", subItems: [] },
  {
    path: "",
    extraPaths: ["/admin/expense/overview", "/admin/expense/management"],
    name: "Expenses",
    subItems: [
      {
        path: "/admin/expense/overview",
        name: "Overview",
      },
      {
        path: "/admin/expense/management",
        name: "Management",
      },
    ],
  },
  { path: "/admin/log", extraPaths: [], name: "Log", subItems: [] },
  { path: "/admin/intro", extraPaths: [], name: "Intro", subItems: [] },
  { path: "/admin/education", extraPaths: [], name: "Education", subItems: [] },
  { path: "/admin/skill", extraPaths: [], name: "Skill", subItems: [] },
  {
    path: "/admin/experience",
    extraPaths: [],
    name: "Experience",
    subItems: [],
  },
  { path: "/admin/role", extraPaths: [], name: "Role", subItems: [] },
  {
    path: "/admin/permission",
    extraPaths: [],
    name: "Permission",
    subItems: [],
  },
];
