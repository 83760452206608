import { DEFAULT_FAIL_MESSAGE } from "../common/constants/app.constant";
import { appActions } from "../redux/reducers/app.reducer";
import { store } from "../redux/store";
import axiosDefault, { AxiosError } from "axios";

export enum SERVER_PATH {
  LOCAL = "http://localhost:3001/api/v1",
  PRODUCTION = "https://thanhphanv.com/api/v1",
}

export const AXIOS_CONFIG = {
  baseURL:
    process.env.REACT_APP_NODE_ENV === "production"
      ? SERVER_PATH.PRODUCTION
      : SERVER_PATH.LOCAL,
};

const axiosInstance = axiosDefault.create(AXIOS_CONFIG);

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (!token) {
    store.dispatch(appActions.setAuth(false));
  }
  config.headers.Authorization = "Bearer " + token;
  return config;
});

axiosInstance.interceptors.response.use(
  (config) => {
    store.dispatch(appActions.setAuth(true));
    return config;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("user");
      store.dispatch(appActions.setAuth(false));
    }

    if (error.response?.status === 403) {
      store.dispatch(appActions.setAuth(false));
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("user");
    }

    store.dispatch(
      appActions.setFailedMessage(
        (error?.response?.data as any)?.error?.message ||
          (error?.response?.data as any)?.message ||
          DEFAULT_FAIL_MESSAGE
      )
    );
    // Clear the message after 2 seconds
    const timeout = setTimeout(() => {
      store.dispatch(appActions.setFailedMessage(""));
      clearTimeout(timeout);
    }, 2000);

    store.dispatch(appActions.setAppLoading(false));
    throw error;
  }
);

export const axios = axiosInstance;
