import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../configs/axios.config";
import { genActionType } from "../../../utils";
import { Permission } from "../../../pages/admin/permissions/entities/permission.entity";

enum FetchStatus {
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
  NOT_INIT = "not_init",
}

interface AdminExperienceState {
  value: Permission[];
  fetchPermissionStatus: {
    status: FetchStatus;
    error?: any;
  };
  addPermissionStatus: {
    status: FetchStatus;
    error?: any;
  };
  removePermissionStatus: {
    status: FetchStatus;
    error?: any;
  };
  selected: Permission | null;
}

const initialState: AdminExperienceState = {
  value: [],
  fetchPermissionStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  addPermissionStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  removePermissionStatus: {
    status: FetchStatus.NOT_INIT,
    error: null,
  },
  selected: null,
};

const fetchPermissionAction = createAsyncThunk(
  genActionType("admin/permission", "fetch-permission"),
  async () => {
    try {
      const res = await axios.get("/admin/permission");
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

const addPermissionAction = createAsyncThunk(
  genActionType("admin/permission", "add-permission"),
  async (payload: Permission) => {
    const res = await axios.post("/admin/permission", payload);
    return res.data;
  }
);

const removePermissionAction = createAsyncThunk(
  genActionType("admin/permission", "remove-permission"),
  async (code: string) => {
    const res = await axios.delete(`/admin/permission/${code}`);
    return res.data;
  }
);

export const adminPermissionSlice = createSlice({
  name: "admin-role-page",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissionAction.pending, (state) => {
        state.fetchPermissionStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(fetchPermissionAction.fulfilled, (state, action) => {
        state.value = action.payload;
        state.fetchPermissionStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(fetchPermissionAction.rejected, (state, action) => {
        state.value = [];
        state.fetchPermissionStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(addPermissionAction.pending, (state) => {
        state.addPermissionStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(addPermissionAction.fulfilled, (state, action) => {
        state.addPermissionStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(addPermissionAction.rejected, (state, action) => {
        state.addPermissionStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      })
      .addCase(removePermissionAction.pending, (state) => {
        state.removePermissionStatus = {
          status: FetchStatus.LOADING,
          error: null,
        };
      })
      .addCase(removePermissionAction.fulfilled, (state, action) => {
        state.removePermissionStatus = {
          status: FetchStatus.SUCCEEDED,
          error: null,
        };
      })
      .addCase(removePermissionAction.rejected, (state, action) => {
        state.removePermissionStatus = {
          status: FetchStatus.FAILED,
          error: action.error,
        };
      });
  },
});

export const adminPermissionActions = adminPermissionSlice.actions;
export default adminPermissionSlice.reducer;
export { fetchPermissionAction, addPermissionAction, removePermissionAction };
