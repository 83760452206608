import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Intro } from "../../../pages/admin/intro/entities/intro.entity";
import { axios } from "../../../configs/axios.config";
import { FETCH_INTRO, UPDATE_INTRO, UPDATE_LOADING_IMAGE } from "../../types";
import axiosDefault from "axios";

interface AdminAboutMeState {
  value: Intro | null;
  fetchingStatus: null | "loading" | "succeeded" | "failed";
  fetchingError?: any;
  updateStatus: null | "loading" | "succeeded" | "failed";
  updateError?: any;
  uploadedSignedUrl?: string;
  uploadImageKey?: string;
  uploadImageStatus: null | "loading" | "succeeded" | "failed";
  uploadImageError?: any;
}

const initialState: AdminAboutMeState = {
  value: null,
  fetchingStatus: null,
  updateStatus: null,
  uploadImageStatus: null,
};

const fetchIntro = createAsyncThunk(FETCH_INTRO, async () => {
  const res = await axios.get("/admin/resume/intro");
  return res.data;
});

const uploadImage = createAsyncThunk(
  UPDATE_LOADING_IMAGE,
  async (file: any) => {
    const {
      data: { url, key },
    } = await axios.post("/s3-storage/presigned-url", {
      contentType: file.type,
      extension: file.name.split(".").pop(),
    });

    await axiosDefault.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    const signedUrl = await axios.post("/s3-storage/signed-url", { key });
    return {
      signedUrl: signedUrl.data.url,
      key,
    };
  }
);

const updateIntro = createAsyncThunk(UPDATE_INTRO, async (data: Intro) => {
  const res = await axios.put("/admin/resume/intro", data);
  return res.data;
});

export const adminAboutMeSlice = createSlice({
  name: "admin-about-me",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntro.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = null;
      })
      .addCase(fetchIntro.fulfilled, (state, action) => {
        state.fetchingStatus = "succeeded";
        state.value = action.payload;
        state.uploadedSignedUrl = action.payload?.avatarPublicUrl;
        state.fetchingError = null;
      })
      .addCase(fetchIntro.rejected, (state, action) => {
        state.fetchingStatus = "failed";
        state.value = null;
        state.fetchingError = action.error;
      })
      .addCase(updateIntro.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(updateIntro.fulfilled, (state, action) => {
        state.value = action.payload;
        state.updateError = null;
        state.updateStatus = "succeeded";
      })
      .addCase(updateIntro.rejected, (state, action) => {
        state.value = null;
        state.updateError = action.error;
        state.updateStatus = "failed";
      })

      .addCase(uploadImage.pending, (state) => {
        state.uploadImageStatus = "loading";
        state.uploadImageError = null;
        state.uploadedSignedUrl = undefined;
        state.uploadImageKey = undefined;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.uploadedSignedUrl = action.payload?.signedUrl;
        state.uploadImageKey = action.payload?.key;
        state.uploadImageError = null;
        state.uploadImageStatus = "succeeded";
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.value = null;
        state.updateError = action.error;
        state.updateStatus = "failed";
        state.uploadedSignedUrl = undefined;
        state.uploadImageKey = undefined;
      });
  },
});

export const adminAboutMeActions = adminAboutMeSlice.actions;
export default adminAboutMeSlice.reducer;
export { fetchIntro, updateIntro, uploadImage };
