import { getImage } from "../../../../utils/get-image";
import { Experience } from "../entities/experience.entity";

export interface ExperienceProps {
  register?: any;
  isLoading?: boolean;
  selected?: Experience | null;
  onAddNewExperience?: () => void;
  onClearInput?: () => void;
  onDeleteExperience?: (experience?: Experience | null) => void;
  sort?: (
    edu: Experience | null | undefined,
    type: "decreaseIndex" | "increaseIndex"
  ) => void;
  uploadedUrl?: string;
  uploadingImage?: boolean;
}

export function ExperienceSection({
  register,
  isLoading,
  selected,
  onAddNewExperience,
  onDeleteExperience,
  onClearInput,
  sort,
  uploadedUrl,
  uploadingImage,
}: ExperienceProps) {
  return (
    <>
      <p className="text-2xl font-bold text-slate-800">Experience</p>
      <div>
        <div className="mt-3 text-left">
          <p className="italic">Choose a image</p>
          <div className="flex justify-between items-center">
            <div>
              <input type="file" {...register("image")} />
            </div>
            {uploadingImage && <p>Uploading...</p>}
            <div>
              <img src={getImage(uploadedUrl)} className="h-12 w-12" />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p className="italic">Company Name</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your company"
            {...register("companyName")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Job Title</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your job title"
            {...register("jobTitle")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Location</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your company location"
            {...register("companyLocation")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Since Period</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your period start"
            {...register("periodStart")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">To Period</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your period to"
            {...register("periodTo")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Detail 1</p>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            rows={2}
            placeholder="Type your details"
            {...register("detail1")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Detail 2</p>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            rows={2}
            placeholder="Type your details"
            {...register("detail2")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Detail 3</p>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            rows={2}
            placeholder="Type your details"
            {...register("detail3")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Detail 4</p>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            rows={2}
            placeholder="Type your details"
            {...register("detail4")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Detail 5</p>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            rows={2}
            placeholder="Type your details"
            {...register("detail5")}
          />
        </div>
        <div className="mt-3">
          <p className="italic">Sort Index</p>
          <button
            type="button"
            disabled={isLoading}
            className="bg-slate-600 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded active:translate-y-1"
            onClick={() => sort && sort(selected, "increaseIndex")}
          >
            {"Go to end"}
          </button>
          <button
            type="button"
            disabled={isLoading}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
            onClick={() => sort && sort(selected, "decreaseIndex")}
          >
            {"Go to first"}
          </button>
        </div>
        <div className="mt-3 text-right flex item-center">
          <p className="italic">Working Now?</p>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-600 ml-2"
            {...register("periodCurrent")}
          />
        </div>
        <div className="mt-3 text-right hidden">
          <p className="italic">ID</p>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Type your period to"
            {...register("id")}
          />
          <input type="text" {...register("companyLogoUrl")} />
        </div>
        <div className="mt-3 text-right">
          <button
            type="button"
            disabled={isLoading}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
            onClick={() => onDeleteExperience && onDeleteExperience(selected)}
          >
            {"Remove"}
          </button>
          <button
            type="button"
            disabled={isLoading}
            className="bg-slate-600 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
            onClick={onClearInput}
          >
            {"Clear Input"}
          </button>
          <button
            type="button"
            disabled={isLoading}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
            onClick={onAddNewExperience}
          >
            {"Add New"}
          </button>
          <button
            disabled={isLoading}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
          >
            {!isLoading ? "Update" : "Updating..."}
          </button>
        </div>
      </div>
    </>
  );
}
