import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from ".";
import { appActions } from "../reducers/app.reducer";
import { DEFAULT_FAIL_MESSAGE } from "../../common/constants/app.constant";

export function useFailedMessage() {
  const dispatch = useAppDispatch();
  const failedMessage = useAppSelector((state) => state.app.failedMessage);

  useEffect(() => {
    if (failedMessage) {
      const timer = setTimeout(() => {
        dispatch(appActions.setFailedMessage(""));
      }, 2000);

      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [failedMessage, dispatch]);

  const setFailMessage = (message: string | null = DEFAULT_FAIL_MESSAGE) => {
    dispatch(appActions.setFailedMessage(message || DEFAULT_FAIL_MESSAGE));
  };

  return setFailMessage;
}
