import moment from "moment";

/**
 *
 * @description Format date
 * @param date
 * @param isCurrent
 * @returns
 */
export function formatDate(
  date: Date | string,
  format: string = "DD/MM/YYYY"
): string {
  return moment(date).format(format);
}
