import { getImage } from "../../../../utils/get-image";
import {
  calculateDuration,
  formatDateString,
} from "../../../../utils/format-date";
import { Skill } from "../entities/skill.entity";

export interface SkillSectionProps {
  skills: Skill[];
  selected?: Skill | null;
  onSelectSkill?: (skill: Skill) => void;
}

export function SkillList({
  skills,
  selected,
  onSelectSkill,
}: SkillSectionProps) {
  return (
    <div className="p-3" style={{ maxHeight: "100%" }}>
      {skills.map((skill, index) => {
        const isSelected = selected && selected.id === skill.id;
        return (
          <div
            key={`skill-${index}`}
            className="flex items-center justify-start mt-10 select-none cursor-pointer skill-item pl-3 p-3"
            style={{
              ...(isSelected
                ? { background: "#005313", color: "#ffffff" }
                : {}),
            }}
            onClick={() => onSelectSkill && onSelectSkill(skill)}
          >
            <div className="skill-item" style={{ width: "120px" }}>
              <img
                src={getImage(skill.skillLogoPublicUrl)}
                alt="company-logo"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div className="p-3" style={{ width: "100%" }}>
              <p className="font-sans text-justify font-bold text-xl">
                {skill.skillName}
              </p>
              <p className="font-sans text-justify text-xl">
                {skill.skillUsedAt}
              </p>
              <p className="font-sans text-justify text-base font-extralight">
                {formatDateString(skill.periodStart, skill.periodCurrent)} -{" "}
                {formatDateString(skill.periodTo, skill.periodCurrent)} ·{" "}
                {calculateDuration(
                  skill.periodStart,
                  skill.periodTo,
                  skill.unit as "months" | "years",
                  skill.periodCurrent
                )}{" "}
                {skill.unit}
              </p>
              <p className="font-sans text-justify text-base font-extralight">
                {skill.isMainSkill ? "Main Skill" : "Soft Skill"}
              </p>
              <p className="font-sans text-justify text-base font-extralight">
                Sort Index: {skill.sortIndex}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
