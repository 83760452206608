// INTRO PAGE
export const FETCH_INTRO = "admin/fetch-intro";
export const UPDATE_INTRO = "admin/update-intro";
export const UPDATE_LOADING_IMAGE = "admin/update-loading-image";

// LOGS PAGE
export const FETCH_LOGS = "admin/fetch-logs";

// EDUCATION PAGE
export const FETCH_EDUCATION = "admin/fetch-education";
export const UPDATE_EDUCATION = "admin/update-education";
export const ADD_EDUCATION = "admin/add-education";
export const DELETE_EDUCATION = "admin/delete-education";

// EXPERIENCE PAGE
export const FETCH_EXPERIENCE = "admin/fetch-experience";
export const UPDATE_EXPERIENCE = "admin/update-experience";
export const ADD_EXPERIENCE = "admin/add-experience";
export const DELETE_EXPERIENCE = "admin/delete-experience";

// SKILL PAGE
export const FETCH_SKILL = "admin/fetch-skill";
export const UPDATE_SKILL = "admin/update-skill";
export const ADD_SKILL = "admin/add-skill";
export const DELETE_SKILL = "admin/delete-skill";
export const SORT_SKILL = "admin/sort-skill";

// ROLE PAGES
export const FETCH_ROLE = "admin/fetch-role";
