import "../../css/root.css";
import "../css/landing.css";
import {
  Experience,
  ExperienceSection,
  Skill,
  SkillSection,
} from "./components/sections";
import { AboutMe, AboutMeProps } from "./components/about-me";
import { Education, EducationSection } from "./components/sections/education";
import { axios } from "../../configs/axios.config";
import { useEffect, useState } from "react";
import { getImage } from "../../utils/get-image";
import { Footer } from "./components/footer";

export function LandingPage() {
  const [aboutMe, setAboutMe] = useState<AboutMeProps>();
  const [experiences, setExperience] = useState<Experience[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [educations, setEducations] = useState<Education[]>([]);

  const getAboutMe = async () => {
    try {
      const res = await axios.get("/landing-page/about-me");
      setAboutMe(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getExperiences = async () => {
    try {
      const res = await axios.get("/landing-page/experience");
      setExperience(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSkills = async () => {
    try {
      const res = await axios.get("/landing-page/skill");
      setSkills(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getEducations = async () => {
    try {
      const res = await axios.get("/landing-page/education");
      setEducations(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAboutMe();
    getExperiences();
    getSkills();
    getEducations();
  }, []);

  return (
    <div className="web-container">
      <div className="cover-container flex flex-col items-center justify-center overflow-hidden">
        <div className="avatar-container rounded-full ring-white ring-2 overflow-hidden p-0">
          <img
            src={getImage(aboutMe?.avatarPublicUrl)}
            alt="logo"
            className="w-72 h-72"
          />
        </div>
      </div>
      <div className="container py-3 px-3 mx-auto sm:p-5 lg:px-20 xl:px-40">
        <AboutMe {...aboutMe} />
        <ExperienceSection experiences={experiences} />
        <SkillSection skills={skills} />
        <EducationSection educations={educations} />
      </div>
      <Footer {...aboutMe} />
    </div>
  );
}
