import { useEffect, useState } from "react";
import { UserItem } from "../components/user-item";
import SearchIcon from "@mui/icons-material/Search";
import { fetchUserAction } from "../../../redux/reducers/chats/user.reducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useAppMultipleLoading } from "../../../redux/hooks/use-app-multipe-loading";
import { User } from "../../user/entities/user.entity";
import { ConversationItem } from "../components/conversation-item";
import {
  fetchConversationAction,
  startConversationAction,
} from "../../../redux/reducers/chats/conversation.reducer";
import { Conversation } from "../entities/conversation.entity";

interface UserSectionProps {
  joinConversation: (conversationId: string) => void;
  selectConversation: (conversation: Conversation) => void;
}

export function UserSection({ selectConversation }: UserSectionProps) {
  const {
    value: { users },
    fetchingProgress,
    selected,
  } = useAppSelector((state) => state.users);
  const { userId: loggedInUserId } = useAppSelector((state) => state.app);
  const {
    value: { conversations },
    fetchingProgress: fetchingProgressConversation,
    selected: selectedConversation,
    startConversationProgress,
  } = useAppSelector((state) => state.conversations);
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useAppDispatch();
  useAppMultipleLoading([
    fetchingProgress.status,
    fetchingProgressConversation.status,
    startConversationProgress.status,
  ]);

  useEffect(() => {
    if (searchInput) {
      const timer = setTimeout(() => {
        dispatch(fetchUserAction({ search: searchInput }));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchInput]);

  const selectUser = async (user: User) => {
    const { payload } = await dispatch(
      startConversationAction({
        friendId: user.id,
      })
    );
    await dispatch(fetchConversationAction({ search: "" }));
    setSearchInput("");
    selectConversation(payload as Conversation);
  };

  return (
    <div className="col-span-3 chat-sidebar border-box overflow-y-scroll py-2 chat-scrollbar-hide">
      <div className="flex chat-search-input-container items-center rounded-3xl">
        <SearchIcon
          fontSize="large"
          className="font-medium"
          sx={{ fontWeight: "bold" }}
        />
        <input
          className="w-full p-2 border-box rounded-3xl"
          placeholder="Search users..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      {searchInput && (
        <div>
          <p className="px-4 text-base font-medium">Searched users:</p>
          {users.map((user) => (
            <UserItem
              user={user}
              key={user?.id}
              selected={selected}
              selectUser={selectUser}
            />
          ))}
        </div>
      )}

      <div>
        <p className="px-4 text-base font-medium">Conversations:</p>
        {(conversations || []).map((conversation) => (
          <ConversationItem
            conversation={conversation}
            key={conversation?.id}
            selected={selectedConversation}
            selectConversation={selectConversation}
            loggedInUserId={loggedInUserId}
          />
        ))}
      </div>
    </div>
  );
}
