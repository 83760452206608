import "./css/education.css";
import { useEffect } from "react";
import { axios } from "../../../configs/axios.config";
import { DashboardLayout } from "../layout/dashboard-layout";
import { Education } from "./entities/education.entity";
import { EducationSection } from "./sections/education-section";
import { useForm } from "react-hook-form";
import { EducationListSection } from "./sections/education-list";
import {
  useAppDispatch,
  useAppLoading,
  useAppSelector,
  useSuccessMessage,
} from "../../../redux/hooks";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RootState } from "../../../redux/store";
import {
  addEducation,
  adminEducationActions,
  deleteEducationAction,
  fetchEducations,
  updateEducations,
  uploadImage as uploadImageAction,
} from "../../../redux/reducers/admin/education.reducer";

export function EducationPage() {
  const setSuccessMessage = useSuccessMessage();
  const { setAppLoading, appLoading } = useAppLoading();
  const {
    value: educations,
    fetchingStatus,
    uploadImageKey,
    uploadImageStatus,
    uploadedSignedUrl,
    selected,
    addStatus,
    updateStatus,
  } = useAppSelector((state: RootState) => state.educations);
  const dispatch = useAppDispatch();

  const { register, handleSubmit, setValue, watch } = useForm();
  const getEducation = async () => {
    return dispatch(fetchEducations());
  };

  useEffect(() => {
    getEducation();
  }, []);

  useEffect(() => {
    if (
      [fetchingStatus, uploadImageStatus, addStatus, updateStatus].some(
        (item) => item === "loading"
      )
    ) {
      setAppLoading(true);
    } else {
      setAppLoading(false);
    }
  }, [
    fetchingStatus,
    setAppLoading,
    addStatus,
    updateStatus,
    uploadImageStatus,
  ]);

  useEffect(() => {
    const files = watch("image");
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        uploadImage(file);
      }
    }
  }, [watch("image")]);

  const uploadImage = async (file: File) => {
    dispatch(uploadImageAction(file));
  };

  useEffect(() => {
    setValue("schoolLogoUrl", uploadImageKey);
  }, [uploadImageKey]);

  const onUpdateEducations = async (data: any) => {
    await dispatch(updateEducations(data));
    await dispatch(fetchEducations());
    clearInput();
    setSuccessMessage("Education updated successfully");
  };

  const onAddEducation = async () => {
    await dispatch(addEducation(watch()));
    await getEducation();
    setSuccessMessage("Education added successfully");
  };

  const onDeleteEducation = async (education?: Education | null) => {
    confirmAlert({
      title: "Confirmation",
      message: `Are you sure you want to delete ${education?.schoolName}`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            deleteEducation(education as Education);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteEducation = async (education?: Education | null) => {
    if (education) {
      await dispatch(deleteEducationAction(education));
      await getEducation();
      setSuccessMessage("Education deleted successfully");
    }
  };

  const onSelectEducation = (education: Education) => {
    dispatch(adminEducationActions.setSelected(education));
    setValue("periodCurrent", education.periodCurrent);
    setValue("periodTo", education.periodTo);
    setValue("periodStart", education.periodStart);
    setValue("schoolLocation", education.schoolLocation);
    setValue("schoolName", education.schoolName);
    setValue("schoolUrl", education.schoolUrl);
    setValue("studyTitle", education.studyTitle);
    setValue("unit", education.unit);
    setValue("id", education.id);
    setValue("schoolLogoUrl", education.schoolLogoUrl);
  };

  const clearInput = () => {
    dispatch(adminEducationActions.setSelected(null));
    setValue("periodCurrent", false);
    setValue("periodTo", "");
    setValue("periodStart", "");
    setValue("schoolLocation", "");
    setValue("schoolName", "");
    setValue("schoolUrl", "");
    setValue("studyTitle", "");
    setValue("unit", "");
    setValue("id", "");
    setValue("schoolLogoUrl", "default");
  };

  const sort = async (
    edu: Education | null | undefined,
    type: "decreaseIndex" | "increaseIndex"
  ) => {
    if (edu) {
      setAppLoading(true);
      await axios.put(`/admin/resume/education/${edu.id}/sort`, {
        type,
      });
      getEducation();
    }
  };

  return (
    <DashboardLayout>
      <div className="grid grid-cols-12 gap-4 p-4">
        <div className="col-span-5">
          <form onSubmit={handleSubmit(onUpdateEducations)}>
            <EducationSection
              register={register}
              isLoading={appLoading}
              onAddNewEducation={onAddEducation}
              onClearInput={clearInput}
              selected={selected}
              onDeleteEducation={onDeleteEducation}
              sort={sort}
              uploadedUrl={uploadedSignedUrl}
            />
          </form>
        </div>
        <div className="col-span-7">
          <EducationListSection
            educations={educations}
            onSelectEducation={onSelectEducation}
            selected={selected}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
