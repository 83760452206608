import { useEffect, useState } from "react";
import { connectSocket } from "./socket";
import { useAppDispatch } from "../../../redux/hooks";
import { appActions } from "../../../redux/reducers/app.reducer";
import { useNavigate } from "react-router-dom";
import { Socket } from "socket.io-client";
import { Conversation } from "../entities/conversation.entity";
import { EVENT_CONVERSATION_JOIN } from "../../../common/events/event";
import { SocketStatus } from "../enum/socket-status.enum";

export function useSocket(selectedConversation: Conversation | null) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [socket] = useState(connectSocket());
  const [socketStatus, setSocketStatus] = useState<SocketStatus>(
    SocketStatus.INITIALIZING
  );

  const onAuthenticated = (data: any) => {
    console.log(data);
    if (socket && selectedConversation) {
      console.log("re-joining conversation...");
      socket.emit(EVENT_CONVERSATION_JOIN, {
        conversationId: selectedConversation.id,
      });
    }
    setSocketStatus(SocketStatus.READY);
  };
  const onUnauthorized = (data: any) => {
    console.log(data);
    setSocketStatus(SocketStatus.UNAUTHORIZED);
    dispatch(appActions.setAuth(false));
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    navigate("/sign-in");
  };

  const authentication = async (socketParams: Socket) => {
    const token = localStorage.getItem("token");
    socketParams.emit("authenticate", {
      token,
    });
  };

  function onDisconnect() {
    console.log("client disconnected");
    setSocketStatus(SocketStatus.DISCONNECTED);
  }

  const onConnect = () => {
    authentication(socket);
  };

  useEffect(() => {
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("authenticated", onAuthenticated);
    socket.on("unauthorized", onUnauthorized);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("pong");
      socket.off("authenticated", onAuthenticated);
      socket.off("unauthorized", onUnauthorized);
      // socket.close();
    };
  }, [selectedConversation?.id]);

  return { socket, socketStatus };
}
