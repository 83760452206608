import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../configs/axios.config";
import { User } from "../../../types/user";
import { FetchingStatus } from "../../../common/types/app-type";
import { genActionType } from "../../../utils";
import { Conversation } from "../../../pages/chat/entities/conversation.entity";
import { SendMessageDto } from "../../../pages/chat/dto/send-message.dto";
import { ReadMessageDto } from "../../../pages/chat/dto/read-message.dto";

interface FetchConversationResponse {
  conversations: Conversation[];
  page: number;
}

interface ChatConversationState {
  value: FetchConversationResponse;
  selected: Conversation | null;
  fetchingProgress: {
    status: FetchingStatus;
    error?: any;
  };
  startConversationProgress: {
    status: FetchingStatus;
    error?: any;
  };
}

const initialState: ChatConversationState = {
  value: {
    conversations: [],
    page: 1,
  },
  fetchingProgress: {
    status: FetchingStatus.Init,
    error: null,
  },
  startConversationProgress: {
    status: FetchingStatus.Init,
    error: null,
  },
  selected: null,
};

const fetchConversationAction = createAsyncThunk(
  genActionType("chat", "fetch-conversation"),
  async (payload: any) => {
    const { search } = payload;
    const res = await axios.get("/chat/conversation", {
      params: {
        search,
      },
    });
    return res.data;
  }
);

const startConversationAction = createAsyncThunk(
  genActionType("chat", "start-conversation"),
  async (payload: any) => {
    try {
      const res = await axios.post("/chat/conversation/start", payload);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const chatPageConversationSlice = createSlice({
  name: "chat-page-conversation",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    updateLastMessageAndUnread: (state, action) => {
      const { conversationId, body, updatedAt, createdAt, unreadUsers } =
        action.payload as SendMessageDto;
      const conversationIndex = state.value.conversations.findIndex(
        (c) => c.id === conversationId
      );
      if (conversationIndex >= 0) {
        const deepClone = JSON.parse(
          JSON.stringify(state.value.conversations[conversationIndex])
        );
        state.value.conversations[conversationIndex] = {
          ...deepClone,
          lastMessage: body,
          updatedAt,
          createdAt,
        };
        state.value.conversations[conversationIndex].unreadUsers.push(
          ...unreadUsers
        );
        state.value.conversations.sort((a, b) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        });
      }
    },
    readMessage: (state, action) => {
      const { conversationId, userId } = action.payload as ReadMessageDto;
      const conversationIndex = state.value.conversations.findIndex(
        (c) => c.id === conversationId
      );
      if (conversationIndex >= 0) {
        state.value.conversations[conversationIndex].unreadUsers =
          state.value.conversations[conversationIndex].unreadUsers.filter(
            (item) => item !== userId
          );

        if (state.selected?.id === conversationId) {
          state.selected.unreadUsers = [
            ...state.value.conversations[conversationIndex].unreadUsers,
          ];
        }
      }
    },
    typingMessage: (state, action) => {
      const { conversationId, userId } = action.payload as ReadMessageDto;
      const conversationIndex = state.value.conversations.findIndex(
        (c) => c.id === conversationId
      );
      if (conversationIndex >= 0) {
        if (state.value.conversations[conversationIndex].typingUsers) {
          state.value.conversations[conversationIndex].typingUsers.push(userId);
        } else {
          state.value.conversations[conversationIndex].typingUsers = [userId];
        }
        if (state.selected?.id === conversationId) {
          state.selected = JSON.parse(
            JSON.stringify(state.value.conversations[conversationIndex])
          );
        }
      }
    },
    unTypingMessage: (state, action) => {
      const { conversationId, userId } = action.payload as ReadMessageDto;
      const conversationIndex = state.value.conversations.findIndex(
        (c) => c.id === conversationId
      );
      if (conversationIndex >= 0) {
        if (state.value.conversations[conversationIndex].typingUsers) {
          state.value.conversations[conversationIndex].typingUsers =
            state.value.conversations[conversationIndex].typingUsers.filter(
              (item) => item !== userId
            );
        } else {
          state.value.conversations[conversationIndex].typingUsers = [];
        }

        if (state.selected?.id === conversationId) {
          state.selected = JSON.parse(
            JSON.stringify(state.value.conversations[conversationIndex])
          );
        }
      }
    },
    setToDefault: (state) => {
      state.value = {
        conversations: [],
        page: 1,
      };
      state.fetchingProgress = {
        status: FetchingStatus.Init,
        error: null,
      };
      state.startConversationProgress = {
        status: FetchingStatus.Init,
        error: null,
      };
      state.selected = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversationAction.pending, (state) => {
        state.fetchingProgress = {
          status: FetchingStatus.Loading,
          error: null,
        };
      })
      .addCase(fetchConversationAction.fulfilled, (state, action) => {
        state.fetchingProgress = {
          status: FetchingStatus.Succeeded,
          error: null,
        };
        state.value = action.payload;
      })
      .addCase(fetchConversationAction.rejected, (state, action) => {
        state.fetchingProgress = {
          status: FetchingStatus.Failed,
          error: action.error,
        };
        state.value = {
          conversations: [],
          page: 1,
        };
      })
      .addCase(startConversationAction.pending, (state) => {
        state.startConversationProgress = {
          status: FetchingStatus.Loading,
          error: null,
        };
      })
      .addCase(startConversationAction.fulfilled, (state, action) => {
        state.startConversationProgress = {
          status: FetchingStatus.Succeeded,
          error: null,
        };
        state.selected = action.payload;
      })
      .addCase(startConversationAction.rejected, (state, action) => {
        state.startConversationProgress = {
          status: FetchingStatus.Failed,
          error: action.error,
        };
      });
  },
});

export const chatConversationActions = chatPageConversationSlice.actions;
export default chatPageConversationSlice.reducer;
export { fetchConversationAction, startConversationAction };
