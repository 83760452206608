import { getImage } from "../../../../utils/get-image";
import {
  calculateDuration,
  formatDateString,
} from "../../../../utils/format-date";
export interface Experience {
  companyLogoUrl: string;
  companyLogoPublicUrl: string;
  companyUrl: string;
  companyName: string;
  jobTitle: string;
  periodStart: Date;
  periodTo: Date;
  companyLocation: string;
  periodCurrent: boolean;
  details: string[];
}

export interface ExperienceSectionProps {
  experiences: Experience[];
}

export function ExperienceSection({ experiences }: ExperienceSectionProps) {
  return (
    <>
      <p className="text-xl md:text-2xl font-bold font-mono mt-3">Experience</p>
      <hr />
      <div>
        {experiences.map((experience, index) => {
          return (
            <div
              key={`experience-${index}`}
              className="flex justify-start mt-10"
            >
              <div className="p-3" style={{ width: "120px" }}>
                <img
                  src={getImage(experience.companyLogoPublicUrl)}
                  alt="company-logo"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className="pl-5" style={{ width: "100%" }}>
                <p className="font-sans text-justify font-bold text-xl">
                  {experience.jobTitle}
                </p>
                <p className="font-sans text-justify text-lg">
                  <a href={experience.companyUrl}>{experience.companyName}</a>
                </p>
                <p className="font-sans text-justify text-base font-extralight">
                  {formatDateString(
                    experience.periodStart,
                    experience.periodCurrent
                  )}{" "}
                  -{" "}
                  {formatDateString(
                    experience.periodTo,
                    experience.periodCurrent
                  )}{" "}
                  ·{" "}
                  {calculateDuration(
                    experience.periodStart,
                    experience.periodTo,
                    "months",
                    experience.periodCurrent
                  )}{" "}
                  months
                  {/* {experience.duration} */}
                </p>
                <p className="font-sans text-justify text-base font-extralight">
                  {experience.companyLocation}
                </p>
                <ul className="font-sans text-justify text-base list-disc pl-5">
                  {experience.details.map((detail, index) => (
                    <li key={`detail-${index}`}>{detail}</li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
