import "./css/skill.css";
import { useEffect } from "react";
import { axios } from "../../../configs/axios.config";
import { DashboardLayout } from "../layout/dashboard-layout";
import { Skill } from "./entities/skill.entity";
import { SkillSection } from "./sections/skill-section";
import { useForm } from "react-hook-form";
import { SkillList } from "./sections/skill-list";
import {
  useAppDispatch,
  useAppLoading,
  useAppSelector,
  useSuccessMessage,
} from "../../../redux/hooks";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RootState } from "../../../redux/store";
import {
  addSkill,
  adminSkillActions,
  deleteSkillAction,
  fetchSkill,
  updateSkills,
  uploadImageAction,
} from "../../../redux/reducers/admin/skill.reducer";

export function SkillPage() {
  const setSuccessMessage = useSuccessMessage();
  const { setAppLoading, appLoading } = useAppLoading();
  const {
    value: skills,
    selected,
    updateStatus,
    uploadImageStatus,
    uploadImageKey,
    uploadedSignedUrl,
    uploadImageError,
    sortStatus,
    fetchingStatus,
    addStatus,
  } = useAppSelector((state: RootState) => state.skills);
  const dispatch = useAppDispatch();

  const { register, handleSubmit, setValue, watch } = useForm();
  const getSkills = async () => {
    return dispatch(fetchSkill());
  };

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    const files = watch("image");
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        uploadImage(file);
      }
    }
  }, [watch("image")]);

  useEffect(() => {
    if (uploadImageKey) {
      setValue("skillLogoUrl", uploadImageKey);
    }
  }, [uploadImageKey]);

  const uploadImage = async (file: File) => {
    dispatch(uploadImageAction(file));
  };

  const onSubmitEducation = async (data: any) => {
    await dispatch(updateSkills(data));
    getSkills();
    setSuccessMessage("Updated successfully!");
  };

  const setSelected = (skill: Skill | null) => {
    if (skill) {
      dispatch(adminSkillActions.setSelected(skill));
    }
  };

  useEffect(() => {
    if (
      [fetchingStatus, addStatus, updateStatus, uploadImageStatus].some(
        (item) => item === "loading"
      )
    ) {
      setAppLoading(true);
    } else {
      setAppLoading(false);
    }
  }, [sortStatus, updateStatus, uploadImageStatus, fetchingStatus]);

  const onClearInput = () => {
    setSelected(null);
    setValue("periodCurrent", false);
    setValue("periodTo", new Date().toISOString());
    setValue("periodStart", new Date().toISOString());
    setValue("isMainSkill", true);
    setValue("skillName", "");
    setValue("skillUrl", "");
    setValue("skillUsedAt", "");
    setValue("unit", "months");
    setValue("id", "");
    setValue("skillLogoUrl", "default");
  };

  const onSelectSkill = (skill: Skill) => {
    setSelected(skill);
    setValue("periodCurrent", skill.periodCurrent);
    setValue("periodTo", skill.periodTo);
    setValue("periodStart", skill.periodStart);
    setValue("isMainSkill", skill.isMainSkill);
    setValue("skillName", skill.skillName);
    setValue("skillUrl", skill.skillUrl);
    setValue("skillUsedAt", skill.skillUsedAt);
    setValue("unit", skill.unit);
    setValue("id", skill.id);
    setValue("skillLogoUrl", skill.skillLogoUrl);
  };

  const onAddNewSkill = async () => {
    const payload = watch();
    delete payload.id;
    if (payload.sortIndex) {
      payload.sortIndex = parseInt(payload.sortIndex);
    }
    await dispatch(addSkill(payload));
    await getSkills();
    setSuccessMessage("Added successfully!");
  };

  const onDeleteSkill = async (skill?: Skill | null) => {
    confirmAlert({
      title: "Confirmation",
      message: `Are you sure you want to delete ${skill?.skillName}`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            deleteSkill(skill as Skill);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteSkill = async (skill: Skill) => {
    await dispatch(deleteSkillAction(skill));
    await getSkills();
    setSuccessMessage("Deleted successfully!");
  };

  const sort = async (
    skill: Skill | null | undefined,
    type: "decreaseIndex" | "increaseIndex"
  ) => {
    if (skill) {
      setAppLoading(true);
      await axios.put(`/admin/resume/skill/${skill.id}/sort`, {
        type,
      });
      getSkills();
    }
  };

  return (
    <DashboardLayout>
      <div className="grid grid-cols-12 gap-4 p-4">
        <div className="col-span-5">
          <form onSubmit={handleSubmit(onSubmitEducation)}>
            <SkillSection
              register={register}
              isLoading={appLoading}
              onAddNewSkill={onAddNewSkill}
              onClearInput={onClearInput}
              onDeleteSkill={onDeleteSkill}
              selected={selected}
              sort={sort}
              uploadedUrl={uploadedSignedUrl}
            />
          </form>
        </div>
        <div className="col-span-7">
          <SkillList
            skills={skills}
            onSelectSkill={onSelectSkill}
            selected={selected}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
