import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import { auth } from './configs/firebase.config';
import { useEffect } from "react";

export function App() {
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    // You can use the refreshed token here.
                    localStorage.setItem('token', token);
                });
            }
        })
    }, [])
    return (
        <BrowserRouter>
            <Routes>
                {
                    routes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))
                }
            </Routes>
        </BrowserRouter>
    )
}