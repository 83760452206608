import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../configs/axios.config";
import {
  ADD_EDUCATION,
  DELETE_EDUCATION,
  FETCH_EDUCATION,
  UPDATE_EDUCATION,
  UPDATE_LOADING_IMAGE,
} from "../../types";
import axiosDefault from "axios";
import { Education } from "../../../pages/admin/education/entities/education.entity";

interface AdminEducationState {
  value: Education[];
  selected: Education | null;
  fetchingStatus: null | "loading" | "succeeded" | "failed";
  fetchingError?: any;
  updateStatus: null | "loading" | "succeeded" | "failed";
  updateError?: any;
  addStatus: null | "loading" | "succeeded" | "failed";
  addError?: any;
  uploadedSignedUrl?: string;
  uploadImageKey?: string;
  uploadImageStatus: null | "loading" | "succeeded" | "failed";
  uploadImageError?: any;
}

const initialState: AdminEducationState = {
  value: [],
  fetchingStatus: null,
  addStatus: null,
  updateStatus: null,
  uploadImageStatus: null,
  selected: null,
};

const fetchEducations = createAsyncThunk(FETCH_EDUCATION, async () => {
  const res = await axios.get("/admin/resume/education");
  return res.data;
});

const uploadImage = createAsyncThunk(
  UPDATE_LOADING_IMAGE,
  async (file: any) => {
    const {
      data: { url, key },
    } = await axios.post("/s3-storage/presigned-url", {
      contentType: file.type,
      extension: file.name.split(".").pop(),
    });

    await axiosDefault.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    const signedUrl = await axios.post("/s3-storage/signed-url", { key });
    return {
      signedUrl: signedUrl.data.url,
      key,
    };
  }
);

const updateEducations = createAsyncThunk(
  UPDATE_EDUCATION,
  async (data: any) => {
    if (data.sortIndex) {
      data.sortIndex = parseInt(data.sortIndex);
    }
    const response = await axios.put(`/admin/resume/education/${data.id}`, {
      ...data,
      periodTo: data.periodTo ? data.periodTo : null,
      periodStart: data.periodStart ? data.periodStart : null,
    });
    return response.data;
  }
);

const addEducation = createAsyncThunk(ADD_EDUCATION, async (payload: any) => {
  delete payload.id;
  if (payload.sortIndex) {
    payload.sortIndex = parseInt(payload.sortIndex);
  }
  const response = await axios.post(`/admin/resume/education`, {
    ...payload,
    periodTo: payload.periodTo ? payload.periodTo : null,
    periodStart: payload.periodStart ? payload.periodStart : null,
  });
  return response.data;
});

const deleteEducationAction = createAsyncThunk(
  DELETE_EDUCATION,
  async (education: Education) => {
    try {
      const response = await axios.delete(
        `/admin/resume/education/${education?.id}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const adminEducationSlice = createSlice({
  name: "admin-education-page",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEducations.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = null;
      })
      .addCase(fetchEducations.fulfilled, (state, action) => {
        state.fetchingStatus = "succeeded";
        state.value = action.payload;
        state.fetchingError = null;
      })
      .addCase(fetchEducations.rejected, (state, action) => {
        state.fetchingStatus = "failed";
        state.value = [];
        state.fetchingError = action.error;
      })
      .addCase(addEducation.pending, (state) => {
        state.addStatus = "loading";
        state.addError = null;
      })
      .addCase(addEducation.fulfilled, (state, action) => {
        state.addStatus = "succeeded";
        state.addError = null;
      })
      .addCase(addEducation.rejected, (state, action) => {
        state.addStatus = "failed";
        state.addError = action.error;
      })
      .addCase(updateEducations.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(updateEducations.fulfilled, (state, action) => {
        state.updateError = null;
        state.updateStatus = "succeeded";
      })
      .addCase(updateEducations.rejected, (state, action) => {
        state.updateError = action.error;
        state.updateStatus = "failed";
      })
      .addCase(uploadImage.pending, (state) => {
        state.uploadImageStatus = "loading";
        state.uploadImageError = null;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.uploadedSignedUrl = action.payload?.signedUrl;
        state.uploadImageKey = action.payload?.key;
        state.uploadImageError = null;
        state.uploadImageStatus = "succeeded";
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.updateError = action.error;
        state.updateStatus = "failed";
        state.uploadImageKey = undefined;
      });
  },
});

export const adminEducationActions = adminEducationSlice.actions;
export default adminEducationSlice.reducer;
export {
  fetchEducations,
  uploadImage,
  updateEducations,
  addEducation,
  deleteEducationAction,
};
