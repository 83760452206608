import { DashboardLayout } from "../layout/dashboard-layout";
import {
  useAppDispatch,
  useAppSelector,
  useSuccessMessage,
} from "../../../redux/hooks";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Permission, RolePermission } from "./entities/permission.entity";
import { PermissionTable } from "./components/permission-table";
import { useAppMultipleLoading } from "../../../redux/hooks/use-app-multipe-loading";
import {
  addPermissionAction,
  adminPermissionActions,
  fetchPermissionAction,
  removePermissionAction,
} from "../../../redux/reducers/admin/permission.reducer";

export function PermissionPage() {
  const setSuccessMessage = useSuccessMessage();
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useAppDispatch();
  const {
    value: permissions,
    selected,
    fetchPermissionStatus,
  } = useAppSelector((state) => state.permissions);

  useAppMultipleLoading([fetchPermissionStatus.status]);

  const getPermissions = async () => {
    dispatch(fetchPermissionAction());
  };

  const removePermission = async () => {
    if (selected && selected.code) {
      await dispatch(removePermissionAction(selected.code));
      await getPermissions();
      setSuccessMessage("Permission removed successfully");
    }
  };

  const onAddPermission = async (data: Permission) => {
    await dispatch(addPermissionAction(data));
    await getPermissions();
    clearInput();
    setSuccessMessage("Permission added successfully");
  };

  const clearInput = () => {
    // clear input
    setValue("code", "");
    setValue("title", "");
    setValue("description", "");
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const handleSelectPermission = async (permission: RolePermission) => {
    if (permission) {
      dispatch(adminPermissionActions.setSelected(permission));
    }
  };

  return (
    <DashboardLayout>
      <div className="grid grid-cols-12 gap-4 p-4">
        <div className="col-span-6">
          <div className="role-permission-table-container">
            <div className="flex">
              <p className="text-2xl font-bold text-slate-800 pb-3">
                Permissions
              </p>
              <div>
                <span className="ml-2 bg-green-600 px-2 py-1 rounded-2xl text-blue-50">
                  {selected?.code}
                </span>
              </div>
            </div>
            <div className="min-h-[40vh]">
              <PermissionTable
                permissions={permissions}
                handleSelect={handleSelectPermission}
                selected={selected}
              />
            </div>
            <div className="mt-2 text-right">
              <button
                type="button"
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2"
                onClick={() => removePermission()}
              >
                {"Remove Permission"}
              </button>
            </div>
          </div>
        </div>
        <div className="col-span-6">
          <form onSubmit={handleSubmit(onAddPermission)}>
            <p className="text-xl font-bold text-slate-800 mt-8">
              Add Permissions
            </p>
            <div className="role-input-container">
              <div className="mt-3">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Type role permission code"
                  {...register("code")}
                />
              </div>
              <div className="mt-3">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Type permission title"
                  {...register("title")}
                />
              </div>
              <div className="mt-3">
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Type permission description (optional)"
                  rows={3}
                  {...register("description")}
                />
              </div>
            </div>
            <div className="role-button-container mt-3 text-right">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded active:translate-y-1 ml-2 min-w-[200px]"
              >
                {"Add Permission"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
}
