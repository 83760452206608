import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../configs/axios.config";
import {
  ADD_EXPERIENCE,
  DELETE_EXPERIENCE,
  FETCH_EXPERIENCE,
  UPDATE_EXPERIENCE,
  UPDATE_LOADING_IMAGE,
} from "../../types";
import axiosDefault from "axios";
import { Experience } from "../../../pages/admin/experience/entities/experience.entity";

interface AdminExperienceState {
  value: Experience[];
  selected: Experience | null;
  fetchingStatus: null | "loading" | "succeeded" | "failed";
  fetchingError?: any;
  updateStatus: null | "loading" | "succeeded" | "failed";
  updateError?: any;
  addStatus: null | "loading" | "succeeded" | "failed";
  addError?: any;
  uploadedSignedUrl?: string;
  uploadImageKey?: string;
  uploadImageStatus: null | "loading" | "succeeded" | "failed";
  uploadImageError?: any;
}

const initialState: AdminExperienceState = {
  value: [],
  fetchingStatus: null,
  addStatus: null,
  updateStatus: null,
  uploadImageStatus: null,
  selected: null,
};

const fetchExperience = createAsyncThunk(FETCH_EXPERIENCE, async () => {
  const res = await axios.get("/admin/resume/experience");
  return res.data;
});

const uploadImageAction = createAsyncThunk(
  UPDATE_LOADING_IMAGE,
  async (file: any) => {
    const {
      data: { url, key },
    } = await axios.post("/s3-storage/presigned-url", {
      contentType: file.type,
      extension: file.name.split(".").pop(),
    });

    await axiosDefault.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    const signedUrl = await axios.post("/s3-storage/signed-url", { key });
    return {
      signedUrl: signedUrl.data.url,
      key,
    };
  }
);

const updateExperiences = createAsyncThunk(
  UPDATE_EXPERIENCE,
  async (data: any) => {
    if (data.sortIndex) {
      data.sortIndex = parseInt(data.sortIndex);
    }
    const response = await axios.put(`/admin/resume/experience/${data.id}`, {
      ...data,
      periodTo: data.periodTo ? data.periodTo : null,
      periodStart: data.periodStart ? data.periodStart : null,
    });
    return response.data;
  }
);

const addExperience = createAsyncThunk(ADD_EXPERIENCE, async (payload: any) => {
  try {
    delete payload.id;
    if (payload.sortIndex) {
      payload.sortIndex = parseInt(payload.sortIndex);
    }
    const response = await axios.post(`/admin/resume/experience`, {
      ...payload,
      periodTo: payload.periodTo ? payload.periodTo : null,
      periodStart: payload.periodStart ? payload.periodStart : null,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

const deleteExperienceAction = createAsyncThunk(
  DELETE_EXPERIENCE,
  async (experience: Experience) => {
    const response = await axios.delete(
      `/admin/resume/experience/${experience?.id}`
    );
    return response.data;
  }
);

export const adminExperienceSlice = createSlice({
  name: "admin-experience-page",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExperience.pending, (state) => {
        state.fetchingStatus = "loading";
        state.fetchingError = null;
      })
      .addCase(fetchExperience.fulfilled, (state, action) => {
        state.fetchingStatus = "succeeded";
        state.value = action.payload;
        state.fetchingError = null;
      })
      .addCase(fetchExperience.rejected, (state, action) => {
        state.fetchingStatus = "failed";
        state.value = [];
        state.fetchingError = action.error;
      })
      .addCase(addExperience.pending, (state) => {
        state.addStatus = "loading";
        state.addError = null;
      })
      .addCase(addExperience.fulfilled, (state, action) => {
        state.addStatus = "succeeded";
        state.addError = null;
      })
      .addCase(addExperience.rejected, (state, action) => {
        state.addStatus = "failed";
        state.addError = action.error;
      })
      .addCase(updateExperiences.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(updateExperiences.fulfilled, (state, action) => {
        state.updateError = null;
        state.updateStatus = "succeeded";
      })
      .addCase(updateExperiences.rejected, (state, action) => {
        state.updateError = action.error;
        state.updateStatus = "failed";
      })
      .addCase(uploadImageAction.pending, (state) => {
        state.uploadImageStatus = "loading";
        state.uploadImageError = null;
      })
      .addCase(uploadImageAction.fulfilled, (state, action) => {
        state.uploadedSignedUrl = action.payload?.signedUrl;
        state.uploadImageKey = action.payload?.key;
        state.uploadImageError = null;
        state.uploadImageStatus = "succeeded";
      })
      .addCase(uploadImageAction.rejected, (state, action) => {
        state.updateError = action.error;
        state.updateStatus = "failed";
        state.uploadImageKey = undefined;
      });
  },
});

export const adminExperienceActions = adminExperienceSlice.actions;
export default adminExperienceSlice.reducer;
export {
  fetchExperience,
  uploadImageAction,
  updateExperiences,
  addExperience,
  deleteExperienceAction,
};
