import { useNavigate } from "react-router-dom";
import { DashboardLayout } from "../layout/dashboard-layout";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export function ExpenseOverviewPages() {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
  return (
    <DashboardLayout>
      <div className="flex flex-col p-4">
        <div className="flex justify-between">
          <div className="flex">
            <h3 className="text-2xl font-bold">Expense Overview - </h3>
            <select
              id="yearSelect"
              className="bg-transparent outline-none focus:outline-none border text-sm rounded-lg ml-2 px-2 text-red-700 font-bold"
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={() => {
              navigate("/admin/expense/management");
            }}
            className="ml-2 bg-green-700 text-white px-2 py-1 rounded hover:bg-green-600 focus:outline-none active:bg-green-500"
          >
            Go to manage page <ArrowForwardIcon />
          </button>
        </div>
        <div>
          <div>
            {" "}
            <p className="text-lg mt-2 font-bold">Monthly Income</p>
            <p className="bg-yellow-100 text-yellow-800 font-semibold px-2 py-1 rounded">
              30.000.000 VND
            </p>
          </div>
        </div>
        <div className="mt-2">
          <table className="min-w-full table-auto border-collapse border border-gray-200">
            <thead className="bg-gray-100 font-bold">
              <tr>
                <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                  From Source
                </th>
                <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Monthly Salary
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-green-700">
                  22.000.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Monthly Salary Bonus
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-green-700">
                  5.000.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Monthly Salary Over Time
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-green-700">
                  3.000.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Monthly Onsite Bonus
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-green-700">
                  4.200.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Income Tax
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-red-700">
                  - 3.000.000 VND
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div>
            <p className="text-lg mt-2 font-bold">Monthly Budget</p>
            <p className="bg-yellow-100 text-yellow-800 font-semibold px-2 py-1 rounded">
              ~ 30.000.000 VND
            </p>
          </div>
        </div>
        <div className="mt-2">
          <table className="min-w-full table-auto border-collapse border border-gray-200">
            <thead className="bg-gray-100 font-bold">
              <tr>
                <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                  Month
                </th>
                <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                  Daily Budget
                </th>
                <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                  Total
                </th>
                <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                  Total Spent
                </th>
                <th className="border-b border-gray-300 px-4 py-2 text-left text-sm font-bold text-gray-700">
                  Remaining
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  January
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-green-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  February
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-red-700">
                  4.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-red-700">
                  - 1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  March
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  April
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  May
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  June
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  July
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  August
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  September
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  October
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  November
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  December
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  105.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  3.000.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
                <td className="border-b border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  1.500.000 VND
                </td>
              </tr>
              {/* Add other months similarly */}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}
