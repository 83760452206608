import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User } from "../../types/user";
import { isMobile } from "react-device-detect";
interface AppState {
  isAuth: boolean | undefined;
  userId?: string | null;
  user?: User;
  isMobile: boolean;
  isShowChannel: boolean;
  isShowChat: boolean;
  successMessage?: string;
  failedMessage?: string;
  appLoading?: boolean;
}

const initialState: AppState = {
  isAuth: !localStorage.getItem("token") ? false : undefined,
  userId: localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : null,
  isMobile,
  isShowChannel: true,
  isShowChat: false,
  successMessage: "",
  failedMessage: "",
  appLoading: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setUserId: (state, action: PayloadAction<string | null>) => {
      state.userId = action.payload;
    },
    setIsShowChannel: (state, action: PayloadAction<boolean>) => {
      state.isShowChannel = action.payload;
    },
    setIsShowChat: (state, action: PayloadAction<boolean>) => {
      state.isShowChat = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
    },
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    setFailedMessage: (state, action: PayloadAction<string>) => {
      state.failedMessage = action.payload;
    },
  },
});

export const appActions = appSlice.actions;
export const selectIsAuth = (state: RootState) => state.app.isAuth;
export default appSlice.reducer;
