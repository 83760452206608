import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from ".";
import { appActions } from "../reducers/app.reducer";
import { RootState } from "../store";

export function useAppMultipleLoading(values: string[]) {
  // no more use, use each action directly
  const appLoading = useAppSelector((state: RootState) => state.app.appLoading);
  const dispatch = useAppDispatch();
  const setAppLoading = (status: boolean) => {
    dispatch(appActions.setAppLoading(status));
  };

  useEffect(() => {
    if (values.some((value) => value === "loading")) {
      setAppLoading(true);
    } else {
      setAppLoading(false);
    }
  }, [...values]);

  return appLoading;
}
