import moment from "moment";

/**
 *
 * @description For formatting on landing page
 * @param date
 * @param isCurrent
 * @returns
 */
export function formatDateString(
  date: Date | null,
  isCurrent: boolean
): string {
  if (!date && isCurrent) {
    return "Current";
  }
  return moment(date).format("DD/MM/YYYY");
}

/**
 * @description For calculating on landing page
 * @param periodStart
 * @param periodTo
 * @param unit
 * @param periodCurrent
 * @returns
 */
export function calculateDuration(
  periodStart: Date,
  periodTo: Date | null,
  unit: "months" | "years" = "months",
  periodCurrent: boolean = false
): number {
  return moment(periodCurrent ? moment() : periodTo).diff(periodStart, unit);
}

export function formatRelativeDate(dateString: string | Date) {
  const date = moment(dateString);
  const now = moment();

  const difference = now.diff(date, "days");

  if (difference > 1) {
    return date.format("DD/MM/YYYY");
  } else {
    return date.fromNow();
  }
}

export function formatRelativeDateForMessage(dateString: string | Date) {
  const date = moment(dateString);
  const now = moment();
  // is the same day in +07:00 timezone
  if (now.diff(date, "days") === 0) {
    return `${date.format("HH:mm")}`;
  }

  return date.format("DD/MM/YYYY HH:mm");
}
