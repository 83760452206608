type MenuItemProps = {
  navigate: any;
  path: string;
  name: string;
  currentPath: string;
  subItems: Array<{ name: string; path: string }>;
  extraPaths: Array<string>;
};

export function MenuItem({
  navigate,
  path,
  name,
  currentPath,
  subItems,
  extraPaths,
}: MenuItemProps) {
  return (
    <div
      className={`site-menu-item cursor-pointer p-2 rounded-lg hover:bg-gray-100 transition-colors`}
      onClick={() => {
        if (path) {
          navigate(path);
        }
        if (!path && subItems.length >= 0) {
          navigate(subItems[0].path);
        }
      }}
    >
      <p
        className={`${
          currentPath === path || extraPaths.includes(currentPath)
            ? "font-bold text-green-700"
            : ""
        }`}
      >
        {name}
      </p>
      {subItems?.length >= 0 && extraPaths.includes(currentPath) && (
        <ul className=" ml-6 mt-2">
          {subItems.map((sub) => (
            <li
              key={sub.path}
              className={`py-1 cursor-pointer ${
                currentPath === sub.path ? "font-bold text-green-700" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click from triggering the parent's onClick
                if (sub.path) {
                  navigate(sub.path);
                }
              }}
            >
              {sub.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
