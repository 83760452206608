import "../../../css/dashboard.css";
import { useNavigate, useLocation } from "react-router-dom";
import { MenuItem } from "../home/menu-item";
import { IconButton, LinearProgress } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "firebase/auth";
import { auth } from "../../../configs/firebase.config";
import { useAppSelector } from "../../../redux/hooks";
import { MenuPaths } from "./menu-path";

type DashboardLayoutProps = {
  children: any;
};

export function DashboardLayout({ children }: DashboardLayoutProps) {
  const { successMessage, appLoading, failedMessage } = useAppSelector(
    (state) => state.app
  );
  const location = useLocation();
  const navigate = useNavigate();

  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("token");
        navigate("/sign-in");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="dashboard-container box-border flex flex-col relative">
      <div className="fixed top-0 z-20 w-full">
        {appLoading && <LinearProgress />}
      </div>
      <div className="px-4 py-2 dashboard-content-header col-span-12">
        <div className="flex justify-between items-center">
          <h6 className="text-2xl logo">ThanhPhanV</h6>
          <div>
            <IconButton onClick={() => handleSignout()}>
              <LogoutIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-0 box-border dashboard-sub-container">
        <div className="col-span-2 box-border dashboard-site-menu">
          {MenuPaths.map((menuItem) => (
            <MenuItem
              key={menuItem.path}
              navigate={navigate}
              path={menuItem.path}
              name={menuItem.name}
              currentPath={location.pathname}
              subItems={menuItem.subItems}
              extraPaths={menuItem.extraPaths}
            />
          ))}
        </div>
        <div className="col-span-10 box-border dashboard-content py-3">
          {children}
        </div>
        {successMessage && (
          <div className="fixed right-4 top-6 bg-green-700 text-white p-3 rounded min-w-[400px]">
            {successMessage}
          </div>
        )}
        {failedMessage && (
          <div className="fixed right-4 top-6 bg-red-700 text-white p-3 rounded min-w-[400px]">
            {failedMessage}
          </div>
        )}
      </div>
    </div>
  );
}
