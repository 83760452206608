import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Conversation } from "../../../pages/chat/entities/conversation.entity";
import { Message } from "../../../pages/chat/entities/message.entity";
import { genActionType } from "../../../utils";
import { axios } from "../../../configs/axios.config";
import { FetchingStatus } from "../../../common/types/app-type";
import { SendMessageDto } from "../../../pages/chat/dto/send-message.dto";
import { ReadMessageDto } from "../../../pages/chat/dto/read-message.dto";

interface ChatConversation extends Conversation {
  messages: Message[];
}

interface ChatConversationState {
  chatConversations: ChatConversation[];
  selected: ChatConversation | null;
  fetchMessageProgress: {
    status: FetchingStatus;
    error: any;
  };
}

const initialState: ChatConversationState = {
  chatConversations: [],
  selected: null,
  fetchMessageProgress: {
    status: FetchingStatus.Init,
    error: null,
  },
};

const fetchMessageAction = createAsyncThunk(
  genActionType("chat", "fetch-message"),
  async (payload: any) => {
    const { conversationId } = payload;
    const res = await axios.get(
      `/chat/conversation/${conversationId}/messages`
    );
    return res.data;
  }
);

export const chatPagechatConversationSlice = createSlice({
  name: "chat-page-chat-conversation",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const conversationIndex = state.chatConversations.findIndex(
        (c) => c.id === action.payload.id
      );
      if (conversationIndex < 0) {
        state.chatConversations.push({
          ...action.payload,
          messages: [],
        });
      } else {
        state.chatConversations[conversationIndex] = {
          ...JSON.parse(
            JSON.stringify(state.chatConversations[conversationIndex])
          ),
          ...action.payload,
        };
      }

      const selectedConversation = state.chatConversations.find(
        (c) => c.id === action.payload.id
      );

      state.selected = selectedConversation
        ? JSON.parse(JSON.stringify(selectedConversation))
        : null;
    },
    addMessages: (state, action) => {
      const { conversationId, messages } = action.payload;
      const conversationIndex = state.chatConversations.findIndex(
        (c) => c.id === conversationId
      );
      if (conversationIndex >= 0) {
        state.chatConversations[conversationIndex].messages.push(...messages);
        state.chatConversations[conversationIndex].unreadUsers.push(
          ...messages[0].unreadUsers
        );
      }
      if (state.selected && state.selected?.id === conversationId) {
        state.selected?.messages.push(...messages);
      }
    },
    readMessage: (state, action) => {
      const { conversationId, userId } = action.payload as ReadMessageDto;
      const conversationIndex = state.chatConversations.findIndex(
        (c) => c.id === conversationId
      );
      if (conversationIndex >= 0) {
        state.chatConversations[conversationIndex].unreadUsers =
          state.chatConversations[conversationIndex].unreadUsers.filter(
            (item) => item !== userId
          );
        if (state.selected && state.selected?.id === conversationId) {
          state.selected = JSON.parse(
            JSON.stringify(state.chatConversations[conversationIndex])
          );
        }
      }
    },
    typingMessage: (state, action) => {
      const { conversationId, userId } = action.payload;
      const conversationIndex = state.chatConversations.findIndex(
        (c) => c.id === conversationId
      );
      if (conversationIndex >= 0) {
        if (state.chatConversations[conversationIndex].typingUsers) {
          state.chatConversations[conversationIndex].typingUsers.push(userId);
        } else {
          state.chatConversations[conversationIndex].typingUsers = [userId];
        }

        if (state.selected && state.selected?.id === conversationId) {
          state.selected = JSON.parse(
            JSON.stringify(state.chatConversations[conversationIndex])
          );
        }
      }
    },
    unTypingMessage: (state, action) => {
      const { conversationId, userId } = action.payload;
      const conversationIndex = state.chatConversations.findIndex(
        (c) => c.id === conversationId
      );
      if (conversationIndex >= 0) {
        if (state.chatConversations[conversationIndex].typingUsers) {
          state.chatConversations[conversationIndex].typingUsers =
            state.chatConversations[conversationIndex].typingUsers.filter(
              (item) => item !== userId
            );
        } else {
          state.chatConversations[conversationIndex].typingUsers = [];
        }
        if (state.selected && state.selected?.id === conversationId) {
          state.selected = JSON.parse(
            JSON.stringify(state.chatConversations[conversationIndex])
          );
        }
      }
    },
    updateLastMessage: (state, action) => {
      const { conversationId, body, updatedAt, createdAt } =
        action.payload as SendMessageDto;
      const conversationIndex = state.chatConversations.findIndex(
        (c) => c.id === conversationId
      );
      if (conversationIndex >= 0) {
        state.chatConversations[conversationIndex] = {
          ...JSON.parse(
            JSON.stringify(state.chatConversations[conversationIndex])
          ),
          lastMessage: body,
          updatedAt,
          createdAt,
        };

        if (state.selected && state.selected.id === conversationId) {
          state.selected = JSON.parse(
            JSON.stringify(state.chatConversations[conversationIndex])
          );
        }
        state.chatConversations.sort((a, b) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        });
      }
    },
    setToDefault: (state) => {
      state.chatConversations = [];
      state.selected = null;
      state.fetchMessageProgress = {
        status: FetchingStatus.Init,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessageAction.pending, (state) => {
        state.fetchMessageProgress = {
          status: FetchingStatus.Loading,
          error: null,
        };
      })
      .addCase(fetchMessageAction.fulfilled, (state, action) => {
        state.fetchMessageProgress = {
          status: FetchingStatus.Succeeded,
          error: null,
        };
        const { conversationId, messages } = action.payload;
        const conversationIndex = state.chatConversations.findIndex(
          (c) => c.id === conversationId
        );
        if (conversationIndex >= 0) {
          state.chatConversations[conversationIndex].messages = messages;
        }
        if (state.selected && state.selected.id === conversationId) {
          state.selected.messages = messages;
        }
      })
      .addCase(fetchMessageAction.rejected, (state, action) => {
        state.fetchMessageProgress = {
          status: FetchingStatus.Failed,
          error: action.error,
        };
      });
  },
});

export const chatPageChatConversationActions =
  chatPagechatConversationSlice.actions;
export default chatPagechatConversationSlice.reducer;
export { fetchMessageAction };
